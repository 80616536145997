import BaseModel from "./BaseModel"
import Link from "./Link"
import { Moment } from 'moment'

export default class Payment extends BaseModel {
  balanceHeader: string
  balanceDue: number
  paymentDateHeader: string
  defaultPaymentDate: Moment
  allowedPaymentDateRange: number
  dateErrorMessage: string
  dateIcon: string
  dateLocale: string
  currencyIcon: string
  selectionHeader: string
  paymentOptions: PaymentOption[]
  postback: PaymentPostback
  alert?: {
    severity: 'success' | 'info' | 'warning' | 'error' | '' | undefined
    variant:  'standard' | 'filled' | 'outlined' | '' | undefined
    icon: string
    title: string
    body: string
  }

  getCasts (): any {
    return {
      balanceDue: 'number',
      defaultPaymentDate: 'datetime',
      allowedPaymentDateRange: 'number',
    }
  }

  setPaymentOptions (a?: []) {
    return a ? a.map(aa => new PaymentOption().init(aa)) : []
  }

  setPostback (a: any) {
    return new PaymentPostback().init(a)
  }
}

export class PaymentOption extends BaseModel {
  id: string
  name: string
  type: string
  termsMarkup: string
  terms: PaymentTerms

  links: Link[]

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }
}

class PaymentPostback extends BaseModel {
  label: string
  variant: 'text' | 'outlined' | 'contained'
  icon: string
  postbackUrl: string
  postbackData: any
  route: string
}

class PaymentTerms extends BaseModel {
  defaultAmount: number
  minAmount: number
  maxAmount: number
  amountErrorMessage: string

  getCasts (): any {
    return {
      defaultAmount: 'number',
      minAmount: 'number',
      maxAmount: 'number',
    }
  }
}
