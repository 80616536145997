import EventBus from '../common/EventBus'
import { observer } from 'mobx-react'
import React from 'react'
import { observable, makeObservable } from 'mobx';
import ApiClientFactory from '../api/ApiClientFactory'
import { Button, Checkbox, FormControlLabel, IconButton, Input, InputLabel, MenuItem, Select, withTheme } from '@material-ui/core'
import Loader from './Loader'
import { ThemedComponentProps } from '@material-ui/core/styles/withTheme'
import DialogManager from './dialog-provider/DialogManager'
import DatePickerDropdown from './DatePickerDropdown'
import PhoneNumberInput from './PhoneNumberInput'
import DeleteIcon from '@material-ui/icons/Delete'
import DynamicIcon from './DynamicIcon'
import moment from 'moment'
import EditIcon from '@material-ui/icons/Edit'
import Link from '../models/Link'
import classNames from 'classnames'
import UnauthorizedError from '../models/UnauthorizedError'

type ProfileProps = {
  url: string
  eventBus: EventBus
}

const ProfileComponent = observer(class ProfileComponent extends React.Component<ProfileProps> {
  private profile?: ProfileData;
  private error?: string;

  private eventBus = new EventBus()

  constructor(props: ProfileProps) {
    super(props);

    makeObservable<ProfileComponent, "profile" | "error">(this, {
      profile: observable,
      error: observable
    });
  }

  componentDidMount (): void {
    this.fetchProfile()

    this.eventBus.on('updated', this.onProfileUpdated)
    this.props.eventBus.on('authenticated', this.onAuthenticated)
  }

  componentWillUnmount (): void {
    this.eventBus.remove(this.onProfileUpdated)
    this.props.eventBus.remove(this.onAuthenticated)
  }

  private onProfileUpdated = () => {
    this.fetchProfile()
  }

  private onAuthenticated = () => {
    this.fetchProfile()
  }

  private fetchProfile = () => {
    this.profile = undefined
    this.error = undefined

    ApiClientFactory.getInstance()
      .get(this.props.url)
      .then(response => {
        this.profile = response.data
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          const unauthorizedError = new UnauthorizedError().init(error.response.data)
          this.props.eventBus.dispatch('unauthorized-error', { unauthorizedError })
          this.error = unauthorizedError.friendlyMessage || unauthorizedError.header || 'Unauthorized'
        } else {
          this.error = 'There was an error loading your statement information'
        }
      })
  }

  render () {
    const ThemedProfileSection = withTheme(ProfileSection)
    return <div className="profile-view-container">
      <div className={classNames('profile-container', this.profile ? 'desktop-card' : undefined)}>
        {
          this.error
            ? this.error
            : this.profile
            ? this.profile.current.map((section, idx) => <ThemedProfileSection
              key={idx}
              profileData={section}
              eventBus={this.eventBus}
            />)
            : <div style={{width: '100%', height: 6}}><Loader/></div>
        }
      </div>
    </div>
  }
});

type SectionProps = {
  profileData: ProfileSectionData
  eventBus: EventBus
}

const ProfileSection = observer(
  class ProfileSection extends React.Component<SectionProps & ThemedComponentProps> {
    private isEditing = false;
    private submitting = false;
    private formValues: any = {};
    private extendedFormValues: any = {};

    private submit = () => {
      this.submitting = true

      const values = this.formValues

      this.props.profileData.form.groupedFields.forEach(groupedFields => {
        groupedFields.fields.forEach(field => {
          if (field.type === 'date') {
            const obj = this.extendedFormValues[field.id]

            if (obj.month.length && obj.day.length && obj.year.length) {
              values[field.id] = `${obj.month}/${obj.day}/${obj.year}`
            }
          }
        })
      })

      ApiClientFactory.getInstance()
        .post(this.props.profileData.postbackUrl, values)
        .then(() => {
          this.props.eventBus.dispatch('updated')
          this.isEditing = false
        })
        .catch(() => DialogManager.show({
          title: 'Error',
          content: 'There was an error loading the payment processor interface',
        }))
        .then(() => this.submitting = false)
    }

    private renderField = (field: Field) => {
      const value = this.formValues[field.id]

      if (field.type === 'textbox') {
        return <div style={{ marginBottom: 8 }}>
          <InputLabel htmlFor={field.id}>{field.title}</InputLabel>
          <Input
            fullWidth
            id={field.id}
            value={value || ''}
            onChange={ev => this.formValues[field.id] = ev.target.value}
            disabled={this.submitting}
          />
        </div>
      } else if (field.type === 'date') {
        return <div>
          <DatePickerDropdown
            month={this.extendedFormValues[field.id].month}
            day={this.extendedFormValues[field.id].day}
            year={this.extendedFormValues[field.id].year}
            onChange={(m, d, y) => {
              this.extendedFormValues[field.id].month = m
              this.extendedFormValues[field.id].day = d
              this.extendedFormValues[field.id].year = y
            }}
            disabled={this.submitting}
          />
        </div>
      } else if (field.type === 'email') {
        return <div>
          <InputLabel htmlFor={field.id}>{field.title}</InputLabel>
          <Input
            fullWidth
            type="email"
            id={field.id}
            value={value || ''}
            onChange={ev => this.formValues[field.id] = ev.target.value}
            disabled={this.submitting}
          />
        </div>
      } else if (field.type === 'array') {
        if (field.items && field.items.type === 'phone') {
          return <>
            {
              (value && value.length)
                ? value.map((v: any, idx: number) => <div key={idx}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: 80 }}>
                      <Select
                        value={v.type}
                        onChange={ev => this.formValues[field.id][idx].type = ev.target.value}
                        fullWidth
                        disabled={this.submitting}
                      >
                        <MenuItem value="Home">Home</MenuItem>
                        <MenuItem value="Cell">Cell</MenuItem>
                      </Select>
                    </div>
                    <div style={{ flex: 1 }}>
                      <Input
                        placeholder="Phone Number"
                        value={v.number || ''}
                        onChange={ev => this.formValues[field.id][idx].number = ev.target.value}
                        disabled={this.submitting}
                        inputComponent={PhoneNumberInput as any}
                      />
                    </div>
                    <div style={{ width: 30 }}>
                      {
                        !this.formValues[field.id][idx].primary
                          ? <IconButton
                            disabled={this.submitting}
                            onClick={() => {
                              this.formValues[field.id] = this.formValues[field.id].filter((v: any, i: number) => i !== idx)
                            }}><DeleteIcon/></IconButton>
                          : null
                      }
                    </div>
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={this.submitting}
                          checked={!!v.primary}
                          onChange={ev => {
                            for (let i = 0; i < this.formValues[field.id].length; ++i) {
                              if (i !== idx) {
                                this.formValues[field.id][i].primary = 0
                              }
                            }
                            if (ev.target.checked) {
                              this.formValues[field.id][idx].primary = (ev.target.checked ? 1 : 0)
                            }
                          }}
                        />
                      }
                      label="Primary"
                    />
                  </div>
                </div>)
                : null
            }
            <div>
              <Button
                variant="contained"
                color="primary"
                disabled={this.submitting}
                onClick={() => {
                  const a = this.formValues[field.id] || []
                  a.push({
                    type: '',
                    number: '',
                    primary: a.length === 0,
                  })

                  this.formValues[field.id] = a
                }}
              >
                Add New
              </Button>
            </div>
          </>
        } else {
          return null
        }
      } else if (field.type === 'checkbox') {
        return <div>
          <FormControlLabel
            control={
              <Checkbox
                disabled={this.submitting}
                checked={!!this.formValues[field.id]}
                onChange={ev => this.formValues[field.id] = ev.target.checked ? 1 : 0}
              />
            }
            label={field.title}
          />
        </div>
      } else {
        return null
      }
    }

    private renderGroupedFields = (groupedFields: FieldGroup) => {
      return <div style={{ marginBottom: 20, display: 'flex' }}>
        <div>
          <DynamicIcon icon={groupedFields.icon} style={{ marginRight: 20, color: this.props?.theme?.palette.primary.main }}/>
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ fontWeight: 'bold', marginBottom: 10 }}>{groupedFields.group}</div>
          {
            groupedFields.fields.map(field => <div key={field.id}>{this.renderField(field)}</div>)
          }
        </div>
      </div>
    }

    private renderEditForm = () => {
      return <div>
        {this.props.profileData.form.groupedFields.map(groupedFields => <div key={groupedFields.group}>{this.renderGroupedFields(groupedFields)}</div>)}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            color="secondary"
            disabled={this.submitting}
            onClick={() => this.isEditing = false}
          >Cancel</Button>
          <Button
            color="primary"
            disabled={this.submitting}
            onClick={() => this.submit()}
          >Save Changes</Button>
        </div>
      </div>
    }

    private renderValues = () => {
      return this.props.profileData.values.map((field, idx) => <div key={idx} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 20 }}>
        <DynamicIcon icon={field.icon} style={{ marginRight: 20, color: this.props?.theme?.palette.primary.main }}/>
        {
          field.value
            ? <div dangerouslySetInnerHTML={{ __html: field.value }}/>
            : <span style={{ color: '#c00', fontStyle: 'italic' }}>update information</span>
        }
      </div>)
    }

    constructor(props: SectionProps & ThemedComponentProps) {
      super(props);

      makeObservable<ProfileSection, "isEditing" | "submitting" | "formValues" | "extendedFormValues">(this, {
        isEditing: observable,
        submitting: observable,
        formValues: observable,
        extendedFormValues: observable
      });
    }

    render () {
      return <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div className="profile-section-header">
          <div className="profile-section-header-text">{this.props.profileData.header}</div>
          <div className="profile-section-header-icon">
            {
              this.isEditing
                ? null
                : <IconButton
                  onClick={() => {
                    this.formValues = Object.assign({}, this.props.profileData.form.data)

                    this.props.profileData!.form.groupedFields.forEach(groupedFields => {
                      groupedFields.fields.forEach(field => {
                        if (field.type === 'date') {
                          const stringValue = this.formValues[field.id]

                          let objValue = {
                            month: '',
                            day: '',
                            year: '',
                          }

                          if (stringValue && stringValue.length) {
                            // convert to date components
                            const d = moment(stringValue)
                            if (d.isValid()) {
                              objValue.month = String(d.month() + 1)
                              objValue.day = String(d.date())
                              objValue.year = String(d.year())
                            }
                          }

                          this.extendedFormValues[field.id] = objValue
                        }
                      })
                    })

                    this.isEditing = true
                  }}
                ><EditIcon/></IconButton>
            }
          </div>
        </div>
        <div style={{ textAlign: 'left' }}>
          {
            this.isEditing
              ? this.renderEditForm()
              : this.renderValues()
          }
        </div>
      </div>
    }
  }
);

type ProfileData = {
  current: ProfileSectionData[]
  links: Link[]
}

type ProfileSectionData = {
  type: string
  header: string
  values: {
    icon: string
    label: string
    value: string
  }[]
  form: {
    errors: []
    groupedFields: FieldGroup[]
    data: any
  }
  postbackUrl: string
}

type FieldGroup = {
  icon: string
  group: string
  fields: Field[]
}

type Field = {
  id: string
  type: 'textbox' | 'array' | 'email' | 'checkbox' | 'date'
  title: string
  alert: string | null
  helperText: string | null
  items: FieldItem | null,
  enum: null
}

type FieldItem = {
  type: 'phone'
}

export default ProfileComponent
