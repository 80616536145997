import HomeView from "../views/HomeView"
import { compileRoutes } from "./routes"
import HelpView from '../views/HelpView'
import PolicyView from '../views/PolicyView'
import TermsView from '../views/TermsView'
import PaymentView from '../views/PaymentView'
import StatementView from '../views/StatementView'
import ProfileView from '../views/ProfileView'
import PaymentRedirectView from '../views/PaymentRedirectView'
import PaymentActionsView from '../views/PaymentActionsView'

export const Routes = {
  home: '/',
  help: '/help',
  policy: '/policy',
  terms: '/terms',
  payment: '/payment',
  paymentRedirect: '/payment-redirect',
  paymentPlanRedirect: '/payment-plan-redirect',
  statement: '/statement',
  profile: '/profile/:tab?',
  paymentPlan: '/profile/payment-plans',
  paymentActivity: '/profile/payment-activity',
  paymentActions: '/payment-actions',
}

compileRoutes(Routes)

export const mapRouteNameToRoute = (routeName: string): string => {
  if (routeName === 'Home') {
    return Routes.home
  } else if (routeName === 'Statement') {
    return Routes.statement
  } else if (routeName === 'Payment') {
    return Routes.paymentRedirect
  } else if (routeName === 'Profile') {
    return Routes.profile
  } else if (routeName === 'Help') {
    return Routes.help
  } else if (routeName === 'PaymentPlan') {
    return Routes.paymentPlan
  } else if (routeName === 'PaymentActivity') {
    return Routes.paymentActivity
  } else if (routeName === 'PaymentActions') {
    return Routes.paymentActions
  } else {
    return Routes.home
  }
}

export const AppRoutes = [
  {
    path: Routes.home,
    component: HomeView,
    strict: false,
  },
  {
    path: Routes.help,
    component: HelpView,
  },
  {
    path: Routes.policy,
    component: PolicyView,
  },
  {
    path: Routes.terms,
    component: TermsView,
  },
  {
    path: Routes.payment,
    component: PaymentView,
  },
  {
    path: Routes.statement,
    component: StatementView,
  },
  {
    path: Routes.profile,
    component: ProfileView,
  },
  {
    path: Routes.paymentRedirect,
    component: PaymentRedirectView,
  },
  {
    path: Routes.paymentActions,
    component: PaymentActionsView,
  }
].map(r => Object.assign({
  exact: true,
  sensitive: true,
  strict: true,
}, r))
