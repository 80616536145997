import React from 'react'
import { observer } from 'mobx-react'
import { computed, makeObservable } from 'mobx';
import SimpleDialog from './SimpleDialog'
import DialogManager, { KeyedDialogDescriptor } from './DialogManager'

const DialogProvider = observer(class DialogProvider extends React.Component {
  constructor(props: any) {
    super(props);

    makeObservable(this, {
      dialogs: computed
    });
  }

  get dialogs(): KeyedDialogDescriptor[] {
    return DialogManager.dialogs
  }

  private onDialogExited = (dialog: KeyedDialogDescriptor) => {
    DialogManager.remove(dialog.uuid)
  }

  private renderDialogs = () => {
    return this.dialogs.map(dialog => <SimpleDialog key={dialog.uuid} title={dialog.title} content={dialog.content} buttons={dialog.buttons} onExited={() => this.onDialogExited(dialog)}/>)
  }

  render () {
    return [
      this.props.children,
      this.renderDialogs(),
    ]
  }
});

export default DialogProvider
