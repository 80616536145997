import React from 'react'
import { InteractionContext } from '../InteractionContext'
import { getRelUrl, joinUrls } from '../common/Util'
import { observer } from 'mobx-react'
import { observable, makeObservable } from 'mobx';
import ApiClientFactory from '../api/ApiClientFactory'
import { Button } from '@material-ui/core'
import AdapterLink from '../components/AdapterLink'
import { mapRouteNameToRoute } from '../routes/AppRoutes'
import { route } from '../routes/routes'
import Loader from '../components/Loader'
import AppStateStore from '../stores/AppStateStore'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { ActionCard } from '../components/ActionCard'
import { ActionCard as ActionCardModel } from '../models/ActionCard'

const HomeView = observer(class HomeView extends React.Component {
  componentDidMount (): void {
    AppStateStore.activeView = 'Home'
  }

  render () {
    const ActionListRouter = withRouter(ActionList)

    return <InteractionContext.Consumer>
      {interaction => <div className="home-container">
        <ActionListRouter url={getRelUrl(interaction.interaction.links, 'actions')}/>
      </div>}
    </InteractionContext.Consumer>
  }
});

type ActionListProps = {
  url: string
} & RouteComponentProps

const ActionList = observer(class ActionList extends React.Component<ActionListProps> {
  private greeting: string | undefined = undefined;
  private text: string | undefined = undefined;
  private detail: {
    imageSource: string | undefined
    label: string | undefined
    value: string | undefined
  } | undefined = undefined;
  private actions: ActionCardModel[] = [];
  private loading = false;

  constructor(props: ActionListProps) {
    super(props);

    makeObservable<ActionList, "greeting" | "text" | "detail" | "actions" | "loading">(this, {
      greeting: observable,
      text: observable,
      detail: observable,
      actions: observable,
      loading: observable
    });
  }

  componentDidMount (): void {
    this.loading = true;

    ApiClientFactory.getInstance()
      .get(this.props.url)
      .then(response => {
        this.actions = response.data.current.map((a: any) => new ActionCardModel().init(a))
        this.greeting = response.data.greeting
        this.text = response.data.text
        this.detail = response.data.detail
        this.loading = false
      })
  }

  private renderMobileAction = (action: ActionCardModel) => {
    return <div className="action-container">
      {
        action.quickAction
          ? <div className="action-button">
            <Button
              fullWidth
              component={AdapterLink}
              to={route(joinUrls([this.props.match.url, mapRouteNameToRoute(action.quickAction.route)]), {}, { rel: action.quickAction.rel })}
              color="primary"
              variant={action.quickAction.variant}
            >
              {action.quickAction.label}
            </Button>
          </div>
          : null
      }
    </div>
  }

  private renderDesktopAction = (action: ActionCardModel) => {
    return <ActionCard collapseMobile={true} actionCard={action} onQuickActionClicked={actionCard => {
      this.props.history.push(route(joinUrls([this.props.match.url, mapRouteNameToRoute(actionCard.quickAction!.route)]), {}, { rel: actionCard.quickAction!.rel }))
    }}/>
  }

  render () {
    return this.loading
      ? <Loader/>
      : <>
        {
          this.greeting
          ? <div className="home-greeting">{this.greeting}</div>
            : null
        }
        {
          this.text
            ? <div className="home-text hide-mobile">{this.text}</div>
            : null
        }
        {
          this.detail
            ? <div className="action-detail hide-desktop">
              <h3 className="header-h3">{this.detail.label}</h3>
              <h4 className="header-h4">{this.detail.value}</h4>
            </div>
            : null
        }
        <div className={classNames(['action-list', this.actions.length > 3 ? 'quad' : undefined])}>
        {this.actions.map((action, idx) => <div key={idx}>
            <div className="hide-desktop">{this.renderMobileAction(action)}</div>
            <div className="hide-mobile">{this.renderDesktopAction(action)}</div>
          </div>
        )}
      </div>
        </>
  }
});

export default HomeView
