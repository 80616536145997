import { DialogDescriptor } from './DialogDescriptor'
import { computed, observable, makeObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid'

export type KeyedDialogDescriptor = {
  uuid: string
} & DialogDescriptor

class DialogManager {
  private _dialogs: KeyedDialogDescriptor[] = [];

  constructor() {
    makeObservable<DialogManager, "_dialogs">(this, {
      _dialogs: observable,
      dialogs: computed
    });
  }

  get dialogs() {
    return this._dialogs
  }

  show = (dialog: DialogDescriptor) => {
    this._dialogs.push({
      ...dialog,
      uuid: uuidv4(),
    })
  }

  remove = (uuid: string) => {
    this._dialogs = this._dialogs.filter(d => d.uuid !== uuid)
  }
}

const dialogManager = new DialogManager()

export default dialogManager as DialogManager
