import React from "react"

type RootRouteContextType = {
  rootRoute: string
}

const defaultState: RootRouteContextType = {
  rootRoute: '',
}

export const RootRouteContext = React.createContext(defaultState)
