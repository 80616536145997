import BaseModel from "./BaseModel"

export default class GroupedValues extends BaseModel {
  group: string
  values: GroupValue[]

  setValues (a?: []) {
    return a ? a.map(aa => new GroupValue().init(aa)) : []
  }
}

export class GroupValue extends BaseModel {
  label: string
  value: string
  type: string
}
