import * as React from "react"
import DSummarySection from "./DSummarySection"
import { observer } from "mobx-react"
import { StepperSummaryDescriptor } from './models/StepperSummaryDescriptor'

type Props = {
  summary: StepperSummaryDescriptor
}

const DSummary = observer(class DSummary extends React.Component<Props> {
  render () {
    return this.props.summary.sections.map((section, idx) => <DSummarySection key={idx} summarySection={section}/>)
  }
});

export default DSummary
