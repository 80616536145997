import React from 'react'
import AppStateStore from '../stores/AppStateStore'

export default class NotFoundView extends React.Component {
  componentDidMount (): void {
    AppStateStore.activeView = 'NotFound'
  }

  render () {
    return <div>
      PAGE NOT FOUND
    </div>
  }
}
