import BaseModel from "./BaseModel"
import Link from './Link'

export default class QuickAction extends BaseModel {
  label: string
  rel: string
  variant: 'text' | 'outlined' | 'contained'
  icon: string
  route: string
}

export class QuickActions extends BaseModel {
  current: QuickAction[] = []
  links: Link[] = []

  setCurrent (a?: []) {
    return a ? a.map(aa => new QuickAction().init(aa)) : []
  }

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }
}
