import React from 'react'
import MaskedInput from 'react-text-mask'

type Props = {
  inputRef: (ref: HTMLInputElement | null) => void
}

export default class PhoneNumberInput extends React.Component<Props> {
  render () {
    const { inputRef, ...rest } = this.props
    return <MaskedInput
      {...rest}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
    />
  }
}
