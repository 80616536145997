import React from 'react'
import { observer } from 'mobx-react'
import { observable, makeObservable } from 'mobx';
import ApiClient from '../../api/ApiClient'
import { CircularProgress } from '@material-ui/core'
import { formatCurrency } from '../../common/Util'
import moment from 'moment'

type Props = {
  url: string
}

const DConfirmation = observer(class DConfirmation extends React.Component<Props> {
  private confirmationData?: ConfirmationData;
  private loading = false;
  private error: string;

  constructor(props: Props) {
    super(props);

    makeObservable<DConfirmation, "confirmationData" | "loading" | "error">(this, {
      confirmationData: observable,
      loading: observable,
      error: observable
    });
  }

  componentDidMount (): void {
    if (!this.confirmationData) {
      this.loadConfirmationData()
    }
  }

  private loadConfirmationData = () => {
    this.loading = true

    ApiClient.getInstance().get(this.props.url)
      .then(response => {
        this.confirmationData = response.data
      })
      .catch(error => this.error = 'There was an error loading the payment method data')
      .then(() => this.loading = false)
  }

  private renderConfirmation = (confirmationData: ConfirmationData) => {
    return <div>
      <div className="payment-confirmation-status-message">{confirmationData.statusMessage}</div>
      <div className="payment-confirmation-message">{confirmationData.confirmation.message}</div>
      <div className="payment-confirmation-group">
        <div className="payment-confirmation-label">
          {confirmationData.amountLabel}
        </div>
        <div className="payment-confirmation-value">
          {formatCurrency(confirmationData.amount)}
        </div>
      </div>
      <div className="payment-confirmation-group">
        <div className="payment-confirmation-label">
          {confirmationData.dateLabel}
        </div>
        <div className="payment-confirmation-value">
          {moment(confirmationData.date, 'YYYY-MM-DDTHH:mm:ss').format('MM/DD/YYYY')}
        </div>
      </div>
      <div className="payment-confirmation-group">
        <div className="payment-confirmation-label">
          Payment Account:

        </div>
        <div className="payment-confirmation-value">
          {confirmationData.accountLabel}
        </div>
      </div>
      <div className="payment-confirmation-group">
        <div className="payment-confirmation-label">
          {confirmationData.confirmationLabel}
        </div>
        <div className="payment-confirmation-value">
          {confirmationData.confirmationCode}
        </div>
      </div>
    </div>
  }

  render () {
    return this.error
      ? <div>{this.error}</div>
      : (this.loading || !this.confirmationData)
        ? <CircularProgress/>
        : this.renderConfirmation(this.confirmationData)
  }
});

type ConfirmationData = {
  id: number
  method: {
    id: number
    method: string
    methodDetail: {
      accountType: string
      accountNo: string
      accountAddl1: string
    }
  }
  status: string
  statusMessage: string
  confirmation: {
    status: string
    code: string
    email: string
    sms: string
    message: string
  }
  amountLabel: string
  amount: number
  dateLabel: string
  date: string
  dateLocale: string
  accountLabel: string
  confirmationLabel: string
  confirmationCode: string
}

export default DConfirmation
