import React from "react"
import Interaction from './models/Interaction'
import UnauthorizedError from './models/UnauthorizedError'

type InteractionContextType = {
  interaction: Interaction
  unauthorizedError?: UnauthorizedError
}

const defaultState: InteractionContextType = {
  interaction: new Interaction(),
  unauthorizedError: undefined,
}

export const InteractionContext = React.createContext(defaultState)
