import * as _ from 'lodash'
import { parseDate } from "../common/Util"

abstract class BaseModel {
  [fieldName: string]: any

  protected visibleProps?: string[]
  protected hiddenProps?: string[]

  getCasts (): any {
    return {}
  }

  init (data: any = {}) {
    Object.getOwnPropertyNames(data).forEach((k: string) => {
      const setterMethod = this[`set${_.upperFirst(k)}`]

      if (setterMethod) {
        this[k] = setterMethod(data[k], data)
      } else if (BaseModel.isSafeProperty(data[k])) {
        const cast = this.getCasts()[k]

        if (cast === 'datetime') {
          this[k] = data[k] ? parseDate(data[k]) : undefined
        } else if (cast === 'number') {
          this[k] = data[k] === undefined
            ? undefined
            : data[k] === null
              ? null
              : Number(data[k])
        } else if (cast === 'boolean') {
          this[k] = data[k] === undefined
            ? undefined
            : data[k] === null
              ? null
              : !!data[k]
        } else {
          this[k] = data[k]
        }
      }
    })

    return this
  }

  private static isSafeProperty (o: any) {
    // try to avoid messing with properties that don't look like fields
    return !_.isFunction(o)
  }

  toObject = () => {
    const props = _.filter(this.visibleProps || Object.getOwnPropertyNames(this), p => BaseModel.isSafeProperty(this[p]) && (!this.hiddenProps || !this.hiddenProps.indexOf(p)))

    const out: any = {}
    props.forEach(p => {
      if (this[p] && this[p].toObject) {
        out[p] = this[p].toObject()
      } else {
        out[p] = this[p]
      }
    })
    return out
  }
}

export default BaseModel
