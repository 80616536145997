import React from 'react'
import { observer } from 'mobx-react'
import { InteractionContext } from '../InteractionContext'
import { formatCurrency, getRelUrl, transformIf } from '../common/Util'
import { computed, observable, makeObservable } from 'mobx';
import ApiClientFactory from '../api/ApiClientFactory'
import Loader from '../components/Loader'
import { Statements } from '../models/Statement'
import EventBus, { EventBusContext } from '../common/EventBus'
import UnauthorizedError from '../models/UnauthorizedError'
import _ from 'lodash'
import { Button, MenuItem, Select, Theme, withTheme } from '@material-ui/core'
import AppStateStore from '../stores/AppStateStore'
import DynamicIcon from '../components/DynamicIcon'
import classNames from 'classnames'
import queryString from 'query-string'

const StatementView = observer(class StatementView extends React.Component {
  componentDidMount (): void {
    AppStateStore.activeView = 'Statement'
  }

  render () {
    const rel = transformIf(queryString.parse(window.location.search).rel, t => String(t))
    return <EventBusContext.Consumer>{eventBusContext =>
      <InteractionContext.Consumer>
        {interactionContext => transformIf(interactionContext.interaction.getQuickActionLinkByRel(rel ?? 'statement'), link => <StatementComponentWithTheme
          url={link.href}
          eventBus={eventBusContext.eventBus}
        />)}
      </InteractionContext.Consumer>
    }</EventBusContext.Consumer>
  }
});

type StatementComponentProps = {
  url: string
  eventBus: EventBus
  theme: Theme
}

const StatementComponent = observer(
  class StatementComponent extends React.Component<StatementComponentProps> {
    private statements?: Statements;
    private error?: string;
    private _selectedStatementId?: string;

    constructor(props: StatementComponentProps) {
      super(props);

      makeObservable<StatementComponent, "statements" | "error" | "_selectedStatementId">(this, {
        statements: observable,
        error: observable,
        _selectedStatementId: observable,
        selectedStatementId: computed,
        selectedStatement: computed
      });
    }

    get selectedStatementId() {
      return this._selectedStatementId
        || ((this.statements && this.statements.current.length)
          ? String(this.statements!.current[0].id)
          : '')
    }

    get selectedStatement() {
      return (this.selectedStatementId && this.statements)
        ? _.find(this.statements.current, s => String(s.id) === this.selectedStatementId)
        : undefined
    }

    componentDidMount (): void {
      this.fetchStatements()

      this.props.eventBus.on('authenticated', this.onAuthenticated)
    }

    componentWillUnmount (): void {
      this.props.eventBus.remove(this.onAuthenticated)
    }

    private onAuthenticated = () => {
      this.fetchStatements()
    }

    private fetchStatements = () => {
      this.statements = undefined
      this.error = undefined

      ApiClientFactory.getInstance()
        .get(this.props.url)
        .then(response => {
          this.statements = new Statements().init(response.data)
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            const unauthorizedError = new UnauthorizedError().init(error.response.data)
            this.props.eventBus.dispatch('unauthorized-error', { unauthorizedError })
            this.error = unauthorizedError.friendlyMessage || unauthorizedError.header || 'Unauthorized'
          } else {
            this.error = 'There was an error loading your statement information'
          }
        })
    }

    private handleStatementFilterChange = (event: React.ChangeEvent<{value: unknown}>) => {
      this._selectedStatementId = String(event.target.value)
    }

    private renderStatement = () => {
      const statement = this.selectedStatement
      const printUrl = getRelUrl(this.statements!.links, statement!.quickAction.rel)

      if (!statement) {
        return null
      }

      return <div className="statement-container">
        <div className="statement-header hide-desktop">{statement.statementHeader}</div>
        <div className="statement-date">{statement.date.format('MMM D, YYYY')}</div>
        <div className="balance-header">{statement.balanceHeader}</div>
        <div className="balance-due">{formatCurrency(statement.balance)}</div>
        <hr className="statement-header-hr"/>
        <div className="statement-amounts-container">
          <table className="statement-amounts">
            <tbody>
            {statement.amounts.map((amount, idx) => <tr key={idx}>
              <td>{amount.label}</td>
              <td style={{ textAlign: 'right' }}>{formatCurrency(amount.total)}</td>
            </tr>)}
            </tbody>
          </table>
        </div>
        <hr className="hide-desktop"/>
        <div className="statement-recipient">
          <b>{statement.recipient.name}</b> - {statement.recipient.type}
        </div>
        <hr className="hide-mobile"/>
        {
          printUrl
            ? <div className="print-button">
              <Button
                className="hide-desktop"
                target="_blank"
                variant="contained"
                color="primary"
                href={printUrl}
                fullWidth
              >Print Detailed Statement</Button>
              <Button
                className="hide-mobile"
                target="_blank"
                color="primary"
                href={printUrl}
                fullWidth
              >Print Detailed Statement</Button>
            </div>
            : null
        }
      </div>
    }

    private renderStatements = (statements: Statements) => {
      if (!statements.current.length) {
        return <div>There are no statements available</div>
      } else {
        return <>
          <div className="statements-filter">
            <div className="statements-filter-header">{statements.filterHeader}</div>
            <div className="statements-filter-mobile">
              <Select
                id="statements-filter"
                value={this.selectedStatementId}
                onChange={this.handleStatementFilterChange}
                fullWidth
              >
                {
                  statements.current.map(statement => <MenuItem key={statement.id} value={statement.id}>{statement.date.format('MMMM D, YYYY')}</MenuItem>)
                }
              </Select>
            </div>
            <div className="statements-filter-desktop">
              {
                statements.current.map(statement => <a
                  className={classNames(statement.id === this.selectedStatement?.id ? 'active' : undefined)}
                  key={statement.id}
                  href="#"
                  onClick={ev => {
                    ev.preventDefault()
                    this._selectedStatementId = String(statement.id)
                  }}
                >
                  <div className="statement-filter-icon" style={statement.id === this.selectedStatement?.id ? { color: this.props.theme.palette.primary.main } : {}}><DynamicIcon icon="LibraryBooksOutlined"/></div>
                  <div className="statement-filter-text">
                    <div className="statement-filter-client">{statement.clientName}</div>
                    <div className="statement-filter-date">{statement.date.format('MMMM D, YYYY')}</div>
                  </div>
                  <div className="statement-filter-balance">{formatCurrency(statement.balance)}</div>
                </a>)
              }
            </div>
          </div>
          {this.renderStatement()}
        </>
      }
    }

    render () {
      return <div className="statements-container">
        {
          this.error
            ? this.error
            : this.statements
            ? this.renderStatements(this.statements)
            : <Loader/>
        }
      </div>
    }
  }
);

const StatementComponentWithTheme = withTheme(StatementComponent)

export default StatementView
