import { observable, makeObservable } from 'mobx';
import * as ls from 'local-storage'

type AuthData = {
  authToken: string
  accessToken: string
  refreshToken: string
}

class AppStateStore {
  authData?: AuthData;
  activeView?: string;

  persistAuthData = (authData: AuthData) => {
    ls.set('authData', JSON.stringify(authData))
    this.authData = authData
  }

  restoreAuthData = () => {
    try {
      this.authData = JSON.parse(ls.get('authData'))
    } catch (e) {
      console.log(e)
    }
  }

  clearAuthData = () => {
    this.authData = undefined
    ls.remove('authData')
  }

  constructor() {
    makeObservable(this, {
      authData: observable,
      activeView: observable
    });
  }
}

const state = new AppStateStore()

export default state as AppStateStore
