import * as React from "react"
import { observer } from "mobx-react"
import DFormTextField from "./DFormTextField"

export enum FormFieldType {
  TextField = 'textbox',
  Date = 'date',
  Checkbox = 'checkbox',
  Array = 'array',
  Radio = 'radio',
  PhoneNumber = 'phonenumber',
  PaymentMethod = 'PaymentMethod',
  Confirmation = 'Confirmation',
}

type Props = {
  id: string
  type: FormFieldType
  title: string
}

const DFormField = observer(class DFormField extends React.Component<Props> {
  private onChange = () => {
  }

  render () {
    if (this.props.type === FormFieldType.TextField) {
      return <DFormTextField id={this.props.id} title={this.props.title} value={''} onChange={this.onChange}/>
    } else {
      return null
    }
  }
});

export default DFormField
