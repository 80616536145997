import * as React from "react"
import { observer } from "mobx-react"
import classNames from 'classnames'
import DAccountCard from "./DAccountCard"
import { RowAlertType, RowType, StepperSummarySectionRowDescriptor, SummaryRowIconCardValue, SummaryRowKeyValueData } from './models/StepperSummarySectionRowDescriptor'
import DynamicIcon from '../DynamicIcon'

type Props = {
  summaryRow: StepperSummarySectionRowDescriptor
}

const DSummaryRow = observer(class DSummaryRow extends React.Component<Props> {
  render () {
    if (this.props.summaryRow.type === RowType.KeyValue) {
      return <KeyValueRowType alert={this.props.summaryRow.alert} data={this.props.summaryRow.value}/>
    } else if (this.props.summaryRow.type === RowType.AccountCard) {
      return <DAccountCard {...this.props.summaryRow.value} />
    } else if (this.props.summaryRow.type === RowType.IconCard) {
      return <IconCardRowType alert={this.props.summaryRow.alert} value={this.props.summaryRow.value}/>
    } else {
      return null
    }
  }
});

type KeyValueRowTypeProps = {
  alert: RowAlertType
  data: SummaryRowKeyValueData
}

const KeyValueRowType = observer(class KeyValueRowType extends React.Component<KeyValueRowTypeProps> {
  render () {
    return <div className={classNames(['stepper-summary-row stepper-summary-key-value-row', this.props.alert ? `alert-${this.props.alert}` : undefined])}>
      <div className="stepper-summary-key-value-row-key">{this.props.data.key}</div>
      <div className="stepper-summary-key-value-row-value">{this.props.data.value}</div>
    </div>
  }
});

type IconCardRowTypeProps = {
  alert: RowAlertType
  value: SummaryRowIconCardValue
}

const IconCardRowType = observer(class IconCardRowType extends React.Component<IconCardRowTypeProps> {
  render () {
    return <div className={classNames(['stepper-summary-row stepper-summary-key-value-row', this.props.alert ? `alert-${this.props.alert}` : undefined])}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {
          this.props.value.icon
            ? <div style={{ paddingRight: 10 }}>
              <DynamicIcon icon={this.props.value.icon} style={{ width: 26 }}/>
            </div>
            : null
        }
        <div>{this.props.value.label}</div>
      </div>
    </div>
  }
});

export default DSummaryRow
