import BaseModel from "./BaseModel"
import Link from './Link'

export default class Authentication extends BaseModel {
  header: string
  factor: {
    label: string
    id: string
    type: string
    locale: string
  }
  agreement: {
    label: string
    id: string
    type: string
    locale: string
    businessText: string
    documentText: string
    documents: AgreementDocument[]
  }
  postback: {
    label: string
    variant: 'text' | 'contained' | 'outlined'
    icon: string
    postbackUrl: string
    postbackData: any
    route: string
  }

  links: Link[]

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }
}

type AgreementDocument = {
  title: string
  rel: string
}
