import { generatePath } from "react-router"
import { transformIf } from "../common/Util"
import * as _ from 'lodash'
import * as path from 'path'
import * as qs from 'querystring'

export const route = (url: string, params: {} = {}, query: {} = {}): string => {
  let t: string = transformIf(url, (n: string) => generatePath(n, params))

  if (Object.getOwnPropertyNames(query).length > 0) {
    t = `${t}${t.indexOf('?') === -1 ? '?' : '&'}${qs.stringify(query)}`
  }

  return t
}

export const compileRoutes = (routes: any, parentPrefix?: string) => {
  const source: any = Object.assign({}, routes)
  delete source['_options'];

  Object.getOwnPropertyNames(source).forEach((k) => {
    const thisPrefix: string | undefined = _.get(routes, '_options.prefix')
    const parts: string[] = []
    parentPrefix && parts.push(parentPrefix)
    thisPrefix && parts.push(thisPrefix)

    const prefix = parts.length ? path.join(...parts) : undefined

    const route = source[k]

    if (_.isString(route)) {
      routes[k] = prefix ? path.join(prefix, route) : route
    } else if (_.isObject(route)) {
      compileRoutes(route, prefix)
    }
  })
}
