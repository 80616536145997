import React, { SyntheticEvent } from 'react'
import { observer } from 'mobx-react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import Config from '../common/Config'
import ApiClientFactory from '../api/ApiClientFactory'
import { autorun, computed, makeObservable, observable } from 'mobx';
import { Button, CircularProgress, createMuiTheme, Dialog, DialogContent, DialogTitle, Link, Link as MuiLink, TextField, ThemeProvider } from '@material-ui/core'
import DialogProvider from '../components/dialog-provider/DialogProvider'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import classNames from 'classnames'
import { AppMenuRouter, AuthHeaderRouter } from '../App'
import AdapterLink from '../components/AdapterLink'
import { route } from '../routes/routes'
import { mapRouteNameToRoute, Routes } from '../routes/AppRoutes'
import { CommonContext } from '../CommonContext'
import { RootRouteContext } from '../RootRouteContext'
import NotFoundView from './NotFoundView'
import PolicyView from './PolicyView'
import HelpView from './HelpView'
import TermsView from './TermsView'
import SigninData, { Assistant, SigninFactor } from '../models/SigninData'
import { getRelUrl, joinUrls } from '../common/Util'
import Authentication from '../models/Authentication'
import LoginDialog from '../components/LoginDialog'
import EventBus from '../common/EventBus'
import UnauthorizedError from '../models/UnauthorizedError'

const GenericLoginView = observer(class GenericLoginView extends React.Component<RouteComponentProps> {
  private authentication?: Authentication;
  private signinData?: SigninData;
  private eventBus = new EventBus()

  constructor(props: RouteComponentProps) {
    super(props);

    makeObservable<GenericLoginView, "authentication" | "signinData">(this, {
      authentication: observable,
      signinData: observable,
      theme: computed
    });
  }

  get theme() {
    const palette: any = {
      primary: { main: '#3c2d82' },
    }

    if (this.signinData?.client) {
      this.signinData.client.colors.forEach(color => {
        palette[color.type] = {
          main: color.code.hex
        }
      })
    }

    return createMuiTheme({
      palette: palette,
    })
  }

  private getSigninData = async () => {
    const params: any = {}

    if (Config.API_SUB_ID) {
      params.sub = Config.API_SUB_ID
    }

    return ApiClientFactory.getInstance()
      .get(`https://e65d2wtk52.execute-api.us-east-1.amazonaws.com/dev/signin`, { params })
  }

  private setFavIcon (src: string | undefined) {
    let link: any = document.querySelector("link[rel~='icon']")

    if (!src && link) {
      link.remove()
    }

    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }

    link.href = src
  }

  componentDidMount () {
    autorun(() => {
      document.title = this.signinData?.client?.shortName || 'Patient App'
      this.setFavIcon(this.signinData?.client?.faviconSource)
    })

    this.getSigninData().then(response => {
      this.signinData = new SigninData().init(response.data)
      this.signinData.fetchClient()
    })

    this.eventBus.on('authenticated', this.onAuthenticated)
  }

  componentWillUnmount () {
    this.eventBus.remove(this.onAuthenticated)
  }

  private onAuthenticated = (ev: {data: string}) => {
    this.props.history.push(joinUrls([ev.data, Routes.home]))
  }

  private renderLoader = () => {
    return <div className="app-loader-container">
      <CircularProgress/>
    </div>
  }

  private renderBody = (signinData: SigninData) => {
    const client = this.signinData?.client

    return <RootRouteContext.Consumer>{({ rootRoute }) =>
      <ThemeProvider theme={this.theme}>
        <DialogProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <RootRouteContext.Provider value={{ rootRoute: '/' }}>
              <CommonContext.Provider value={{ commonData: signinData }}>
                <div className={classNames(['app-container'])}>
                  <div className="desktop-header hide-mobile">
                    {
                      client?.logoSource
                        ? <div className="desktop-client-logo">
                          <img src={client.logoSource}/>
                        </div>
                        : null
                    }
                    <div className="desktop-auth-header">
                      <AuthHeaderRouter rootRoute="/"/>
                    </div>
                  </div>
                  <div className="hide-desktop">
                    <AppMenuRouter
                      rootRoute="/"
                      position="top"
                      quickActions={signinData.quickactions}
                      theme={this.theme}
                    />
                  </div>
                  {
                    (client?.logoSource)
                      ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} className="hide-desktop">
                        <div className="app-client-logo">
                          <img src={client.logoSource}/>
                        </div>
                      </div>
                      : null
                  }
                  <div className="app-content-wrapper">
                    <div className="desktop-left-nav hide-mobile">
                      <AppMenuRouter
                        rootRoute="/"
                        position="left"
                        quickActions={signinData.quickactions}
                        theme={this.theme}
                      />
                    </div>
                    <div
                      className={classNames(['app-content'])}>
                      <Switch>
                        <Route
                          path="/"
                          component={() => <GenericLoginComponent signinData={signinData} eventBus={this.eventBus} rootRoute={rootRoute} history={this.props.history}/>}
                          exact={true}
                        />
                        <Route
                          path={Routes.policy}
                          component={PolicyView}
                          exact={true}
                        />
                        <Route
                          path={Routes.help}
                          component={HelpView}
                          exact={true}
                        />
                        <Route
                          path={Routes.terms}
                          component={TermsView}
                          exact={true}
                        />
                        <Route component={NotFoundView}/>
                      </Switch>
                    </div>
                  </div>
                  <div>
                    {this.renderFooter(signinData)}
                  </div>
                </div>
                {this.signinData ? <LoginDialog eventBus={this.eventBus} client={client}/> : null}
              </CommonContext.Provider>
            </RootRouteContext.Provider>
          </MuiPickersUtilsProvider>
        </DialogProvider>
      </ThemeProvider>
    }</RootRouteContext.Consumer>
  }

  private renderFooter = (signinData: SigninData) => {
    return <div className="app-footer">
      <MuiLink color="inherit" component={AdapterLink} to={route(Routes.policy)}>{signinData.policy.label}</MuiLink>
      &nbsp;
      &nbsp;
      &nbsp;
      <MuiLink color="inherit" component={AdapterLink} to={route(Routes.terms)}>{signinData.term.label}</MuiLink>
    </div>
  }

  render () {
    return this.signinData && this.signinData.client
      ? this.renderBody(this.signinData)
      : this.renderLoader()
  }
});

type GenericLoginComponentProps = {
  signinData: SigninData
  eventBus: EventBus
  rootRoute: string
} & Partial<RouteComponentProps>

const GenericLoginComponent = observer(
  class GenericLoginComponent extends React.Component<GenericLoginComponentProps> {
    formError?: string;
    submitting = false;
    inputValue = '';
    renderInstructorDialog = false;
    renderAssistantDialog = false;
    isMobile = false;

    private onResize = (ev: UIEvent) => {
      this.checkIsMobileWidth()
    }

    private checkIsMobileWidth = () => {
      this.isMobile = window.innerWidth < 1024
    }

    constructor(props: GenericLoginComponentProps) {
      super(props);

      makeObservable(this, {
        formError: observable,
        submitting: observable,
        inputValue: observable,
        renderInstructorDialog: observable,
        renderAssistantDialog: observable,
        isMobile: observable
      });
    }

    componentDidMount () {
      this.checkIsMobileWidth()
      window.addEventListener('resize', this.onResize)
    }

    componentWillUnmount () {
      window.removeEventListener('resize', this.onResize)
    }

    private onSubmit = (ev: SyntheticEvent) => {
      ev.preventDefault()

      if (this.inputValue && !this.submitting) {
        this.submitting = true
        this.formError = undefined

        const data = Object.assign({}, this.props.signinData.postback.postbackData)

        if (this.props.signinData.factor.type === 'text') {
          data[this.props.signinData.factor.id] = this.inputValue
        }

        ApiClientFactory.getInstance()
          .post(this.props.signinData.postback.postbackUrl, data)
          .then(response => {
            console.log(response.data)
          })
          .catch(error => {
            if (error.response?.status === 401) {
              const unauthorizedError = new UnauthorizedError().init(error.response.data)
              this.props.eventBus.dispatch('unauthorized-error', { unauthorizedError })
            } else {
              this.formError = error.response?.data?.msg || (error.response?.status === 400 ? 'Invalid login' : 'Server error')
            }
          })
          .then(() => this.submitting = false)
      }
    }

    private renderInput = (factor: SigninFactor, error?: string) => {
      if (factor.type === 'text') {
        return <TextField
          error={!!error}
          fullWidth
          type="text"
          label={factor.label}
          variant="outlined"
          margin="none"
          value={this.inputValue}
          onChange={ev => this.inputValue = ev.target.value}
          disabled={this.submitting}
          helperText={error}
        />
      } else {
        return null
      }
    }

    private navigateAssistant = (assistant: Assistant) => {

    }

    render () {
      return <div className="generic-login-container">
        {
          !this.isMobile
            ? <div style={{ textAlign: 'center' }}>
              {
                this.props.signinData.client?.logoSource
                  ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <div className="app-client-logo"><img src={this.props.signinData.client.logoSource}/></div>
                  </div>
                  : null
              }
            </div>
            : null
        }
        <div style={{ marginTop: 20, textAlign: 'center' }}>
          <div style={{ fontSize: 28, color: '#333' }}>{this.props.signinData.header}</div>
          <div style={{ fontSize: 21, color: '#333', marginTop: 10 }}>{this.props.signinData.body}</div>
        </div>
        <div>
          <form method="post" onSubmit={this.onSubmit}>
            <div style={{ marginTop: 10, marginBottom: 60 }}>
              <div>
                {this.renderInput(this.props.signinData.factor, this.formError)}
              </div>
              {
                this.props.signinData.assistant?.quickaction
                  ? <div style={{ marginTop: 10 }}>
                    {
                      this.props.signinData.assistant?.header
                        ? <p style={{ marginBottom: 4 }}>{this.props.signinData.assistant.header}</p>
                        : null
                    }
                    <Link
                      color="primary"
                      href="#"
                      style={{ fontWeight: 500 }}
                      onClick={(ev: SyntheticEvent) => {
                        ev.preventDefault()
                        this.props.signinData.assistant?.quickaction?.route
                          ? this.props.history!.push(route(joinUrls([this.props.rootRoute, mapRouteNameToRoute(this.props.signinData.assistant?.quickaction.route)]), {}, { rel: this.props.signinData.assistant?.quickaction.rel }))
                          : this.renderAssistantDialog = true
                      }}
                    >{this.props.signinData.assistant.quickaction.label}</Link>
                  </div>
                  : null
              }
              {
                this.props.signinData.instructor?.quickaction
                  ? <div style={{ marginTop: 10 }}>
                    {
                      this.props.signinData.instructor?.header
                        ? <p style={{ marginBottom: 4 }}>{this.props.signinData.instructor.header}</p>
                        : null
                    }
                    <Link
                      color="primary"
                      href="#"
                      style={{ fontWeight: 500 }}
                      onClick={(ev: SyntheticEvent) => {
                        ev.preventDefault()
                        this.props.signinData.instructor?.quickaction?.route
                          ? this.props.history!.push(route(joinUrls([this.props.rootRoute, mapRouteNameToRoute(this.props.signinData.instructor?.quickaction.route)]), {}, { rel: this.props.signinData.instructor?.quickaction.rel }))
                          : this.renderInstructorDialog = true
                      }}
                    >{this.props.signinData.instructor.quickaction.label}</Link>
                  </div>
                  : null
              }
            </div>
            <div style={{ textAlign: 'right' }}>
              <Button
                type="submit"
                color="primary"
                variant={this.props.signinData.postback.variant}
                disabled={this.submitting || !this.inputValue}
                fullWidth={this.isMobile}
              >
                {this.props.signinData.postback.label}
              </Button>
            </div>
          </form>
          {
            this.renderAssistantDialog
              ? <Dialog
                onClose={() => this.renderAssistantDialog = false}
                aria-labelledby="simple-dialog-title"
                open={true}
                fullWidth={true}
                maxWidth='lg'
              >
                <DialogTitle id="simple-dialog-title">
                  <Button fullWidth onClick={() => this.renderAssistantDialog = false}>Close Window</Button>
                </DialogTitle>
                <DialogContent>
                  {
                    this.props.signinData.assistant?.quickaction
                      ? <img src={getRelUrl(this.props.signinData.links, this.props.signinData.assistant.quickaction.rel)}/>
                      : null
                  }
                </DialogContent>
              </Dialog>
              : null
          }
          {
            this.renderInstructorDialog
              ? <Dialog
                onClose={() => this.renderInstructorDialog = false}
                aria-labelledby="simple-dialog-title"
                open={true}
                fullWidth={true}
                maxWidth='lg'
              >
                <DialogTitle id="simple-dialog-title">
                  <Button fullWidth onClick={() => this.renderInstructorDialog = false}>Close Window</Button>
                </DialogTitle>
                <DialogContent>
                  {
                    this.props.signinData.instructor?.quickaction
                      ? <img src={getRelUrl(this.props.signinData.links, this.props.signinData.instructor.quickaction.rel)}/>
                      : null
                  }
                </DialogContent>
              </Dialog>
              : null
          }
        </div>
      </div>
    }
  }
);

export default GenericLoginView
