import React from 'react'
import { observer } from 'mobx-react'
import { Redirect } from 'react-router'
import { Routes } from '../routes/AppRoutes'
import { route } from '../routes/routes'
import { RouteComponentProps } from 'react-router-dom'

const PaymentRedirectView = observer(
  class PaymentRedirectView extends React.Component<RouteComponentProps<{slug: string}>> {
    render () {
      return <Redirect to={route(`/${this.props.match.params.slug}${Routes.payment}${window.location.search}`)}/>
    }
  }
);

export default PaymentRedirectView
