import BaseModel from "./BaseModel"
import Link from './Link'
import { Moment } from 'moment'
import QuickAction from './QuickAction'

export default class Statement extends BaseModel {
  id: number
  date: Moment
  type: string
  clientName: string
  recipient: {
    name: string
    type: string
    destination: {
      address: {
        line1: string
        line2: string
        city: string
        stateOrProvince: string
        postalCode: string
        country: string
      }
      email: string
      sms: string
    }
  }
  statementHeader: string
  statementDescription: string
  balanceHeader: string
  balance: number
  amounts: StatementAmount[]
  accounts: StatementAccount[]
  quickAction: QuickAction

  setQuickAction (a?: any) {
    return a ? new QuickAction().init(a) : undefined
  }

  setAmounts (a?: []) {
    return a ? a.map(aa => new StatementAmount().init(aa)) : []
  }

  setAccounts (a?: []) {
    return a ? a.map(aa => new StatementAccount().init(aa)) : []
  }

  getCasts (): any {
    return {
      date: 'datetime',
    }
  }
}

export class Statements extends BaseModel {
  filterHeader: string
  filterLocale: string
  current: Statement[]
  links: Link[]

  setCurrent (a?: []) {
    return a ? a.map(aa => new Statement().init(aa)) : []
  }

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }
}

class StatementAccount extends BaseModel {
  reference: string
  responsibleParty: string
  clientName: string
  balance: number
  fees: StatementFee[]
  services: StatementService[]

  setFees (a?: []) {
    return a ? a.map(aa => new StatementFee().init(aa)) : []
  }

  setServices (a?: []) {
    return a ? a.map(aa => new StatementService().init(aa)) : []
  }
}

class StatementService extends BaseModel {
  reference: string
  patientName: string
  facility: string
  dateOfService: Moment
  totalCharges: number
  balance: number
  fees: StatementFee[]
  amounts: StatementServiceAmount[]
  balancesHeader: string
  balances: StatementServiceAmount[]
  transactions: StatementServiceTransaction[]
  insurances: StatementServiceInsurance[]
  physicians: string[]
  services: string[]
  id: number

  setFees (a?: []) {
    return a ? a.map(aa => new StatementFee().init(aa)) : []
  }

  setAmounts (a?: []) {
    return a ? a.map(aa => new StatementServiceAmount().init(aa)) : []
  }

  setBalances (a?: []) {
    return a ? a.map(aa => new StatementServiceAmount().init(aa)) : []
  }

  setTransactions (a?: []) {
    return a ? a.map(aa => new StatementServiceTransaction().init(aa)) : []
  }

  setInsurances (a?: []) {
    return a ? a.map(aa => new StatementServiceInsurance().init(aa)) : []
  }

  getCasts (): any {
    return {
      dateOfService: 'datetime',
    }
  }
}

class StatementServiceInsurance extends BaseModel {
  id: number
  alert: number
  shortLabel: string
  description: string
  extendedInformation: StatementServiceInsuranceExtendedInformation

  setExtendedInformation (a?: any) {
    return a ? new StatementServiceInsuranceExtendedInformation().init(a) : undefined
  }
}

class StatementServiceInsuranceExtendedInformation extends BaseModel {
  longLabel: string
  name: string
  subscriberName: string
  memberID: string
  group: string
  amounts: {
    index: number
    label: string
    total: number
  }[]
  transactions: StatementServiceTransaction[]
  claims: StatementServiceInsuranceClaim[]

  setTransactions (a?: []) {
    return a ? a.map(aa => new StatementServiceTransaction().init(aa)) : []
  }

  setClaims (a?: []) {
    return a ? a.map(aa => new StatementServiceInsuranceClaim().init(aa)) : []
  }
}

class StatementServiceInsuranceClaim extends BaseModel {
  date: Moment
  description: string

  getCasts (): any {
    return {
      date: 'datetime',
    }
  }
}

class StatementServiceTransaction extends BaseModel {
  id: number
  date: Moment
  type: string
  amount: number
  payor: string

  getCasts (): any {
    return {
      date: 'datetime',
    }
  }
}

class StatementFee extends BaseModel {

}

class StatementServiceAmount extends BaseModel {
  index: number
  label: string
  total: number

  getCasts (): any {
    return {
      total: 'number',
    }
  }
}

class StatementAmount extends BaseModel {
  label: string
  total: number

  getCasts (): any {
    return {
      total: 'number',
    }
  }
}
