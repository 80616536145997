import { textval } from './Util'

class Config {
  NODE_ENV?: string = process.env.NODE_ENV
  ENV?: string = process.env.REACT_APP_ENV
  API_URL?: string = process.env.REACT_APP_API_URL
  API_SUB_ID?: string = process.env.REACT_APP_API_SUB_ID
  AUTH_NET_SANDBOX: boolean = !!textval(process.env.REACT_APP_AUTH_NET_SANDBOX)
  AUTH_NET_CLIENT_KEY: string = process.env.REACT_APP_AUTH_NET_CLIENT_KEY || ''
  AUTH_NET_API_LOGIN_ID: string = process.env.REACT_APP_AUTH_NET_API_LOGIN_ID || ''
  CYBERSOURCE_TEST_MODE: boolean = !!textval(process.env.REACT_APP_CYBERSOURCE_TEST_MODE)
}

const config = new Config()

export default config as Config
