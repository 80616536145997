import React from 'react'
import { ActionCard as ActionCardModel } from '../models/ActionCard'
import { Tab, Tabs } from '@material-ui/core'
import { observer } from 'mobx-react'
import { computed, observable, makeObservable } from 'mobx';
import _ from 'lodash'
import { ActionCard } from './ActionCard'

type Props = {
  greeting?: string
  text?: string
  detail?: {
    imageSource: string | undefined
    label: string | undefined
    value: string | undefined
  }
  cards: ActionCardModel[]
  onQuickActionClicked: (actionCard: ActionCardModel) => void
  collapseMobile?: boolean
}

export const ActionCardsList = observer(class ActionCardsList extends React.Component<Props> {
  private selectedTab?: string;

  constructor(props: Props) {
    super(props);

    makeObservable<ActionCardsList, "selectedTab" | "actionCardCategories" | "activeTab" | "visibleActionCards">(this, {
      selectedTab: observable,
      actionCardCategories: computed,
      activeTab: computed,
      visibleActionCards: computed
    });
  }

  private get actionCardCategories() {
    return _.uniq(this.props.cards.map(c => c.category) ?? [])
  }

  private get activeTab() {
    return this.selectedTab ?? (this.actionCardCategories.length ? this.actionCardCategories[0] : undefined)
  }

  private get visibleActionCards() {
    if (this.actionCardCategories.length < 2) {
      return this.props.cards
    }

    return this.props.cards.filter(c => c.category === this.activeTab)
  }

  render () {
    return <>
      {
        this.actionCardCategories.length > 1
          ? <div className="payment-plans-tab-container">
            <Tabs value={this.activeTab} onChange={(ev, value) => this.selectedTab = value} variant="fullWidth">
              {
                this.actionCardCategories.map(cat => <Tab key={cat} label={cat} value={cat}/>)
              }
            </Tabs>
          </div>
          : null
      }
      {
        this.props.greeting
          ? <div className="home-greeting">{this.props.greeting}</div>
          : null
      }
      {
        this.props.text
          ? <div className="home-text">{this.props.text}</div>
          : null
      }
      {
        this.props.detail
          ? <div className="action-detail hide-desktop" style={{ marginBottom: 20 }}>
            <h3 className="header-h3">{this.props.detail.label}</h3>
            <h4 className="header-h4">{this.props.detail.value}</h4>
          </div>
          : null
      }
      <div className="action-cards-list">
        {
          this.visibleActionCards.map((actionCard, idx) => <ActionCard collapseMobile={this.props.collapseMobile} key={idx} actionCard={actionCard} onQuickActionClicked={this.props.onQuickActionClicked}/>)
        }
      </div>
    </>
  }
});
