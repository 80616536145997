import React from 'react'
import { MenuItem, Select, TextField } from '@material-ui/core'
import { observer } from 'mobx-react'
import _ from 'lodash'

type Props = {
  label?: string
  month: string
  day: string
  year: string
  onChange: (month: string, day: string, year: string) => void
  disabled?: boolean
}

const DatePickerDropdown = observer(class DatePickerDropdown extends React.Component<Props> {
  render () {
    return <div>
      {this.props.label ? <label>{this.props.label}</label> : null}
      <div style={{ display: 'flex' }}>
        <div style={{ paddingRight: 10, width: 120 }}>
          <Select
            label="Month"
            value={this.props.month}
            onChange={d => this.props.onChange(String(d.target.value), this.props.day, this.props.year)}
            fullWidth
            displayEmpty={true}
          >
            <MenuItem value="" disabled>MM</MenuItem>
            <MenuItem value="1">January</MenuItem>
            <MenuItem value="2">February</MenuItem>
            <MenuItem value="3">March</MenuItem>
            <MenuItem value="4">April</MenuItem>
            <MenuItem value="5">May</MenuItem>
            <MenuItem value="6">June</MenuItem>
            <MenuItem value="7">July</MenuItem>
            <MenuItem value="8">August</MenuItem>
            <MenuItem value="9">September</MenuItem>
            <MenuItem value="10">October</MenuItem>
            <MenuItem value="11">November</MenuItem>
            <MenuItem value="12">December</MenuItem>
          </Select>
        </div>
        <div style={{ width: 60, paddingRight: 10 }}>
          <Select
            label="Day"
            value={this.props.day}
            onChange={d => this.props.onChange(this.props.month, String(d.target.value), this.props.year)}
            fullWidth
            displayEmpty={true}
          >
            <MenuItem value="" disabled>DD</MenuItem>
            {
              _.range(1, 32).map(day => <MenuItem key={day} value={String(day)}>{day}</MenuItem>)
            }
          </Select>
        </div>
        <div style={{ width: 80 }}>
          <div>
            <TextField
              type="number"
              value={this.props.year}
              placeholder="YYYY"
              onChange={d => this.props.onChange(this.props.month, this.props.day, String(d.target.value))}
            />
          </div>
        </div>
      </div>
    </div>
  }
});

export default DatePickerDropdown
