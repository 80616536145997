import React from 'react'
import { observer } from 'mobx-react'
import GroupedValuesPane from '../components/GroupedValuesPane'
import ApiClientFactory from '../api/ApiClientFactory'
import { getRelUrl } from '../common/Util'
import AppStateStore from '../stores/AppStateStore'
import { CommonContext } from '../CommonContext'

const TermsView = observer(class TermsView extends React.Component {
  componentDidMount (): void {
    AppStateStore.activeView = 'Terms'
  }

  private getGroupedValues = async (url: string) => {
    return ApiClientFactory.getInstance()
      .get(url)
  }

  render () {
    return <CommonContext.Consumer>
      {data => <div className="statements-container">
        <GroupedValuesPane adapter={this.getGroupedValues(getRelUrl(data.commonData.links, data.commonData.term.rel))}/>
      </div>}
    </CommonContext.Consumer>
  }
});

export default TermsView
