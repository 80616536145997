import axios, { CancelTokenSource } from 'axios'
import Config from "../common/Config"
import { ApiClientInterface } from './ApiClientFactory'
import AppStateStore from '../stores/AppStateStore'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'

export interface RequestOptions {
  cancelTokenSource?: CancelTokenSource
}

class ApiClient implements ApiClientInterface {
  getInstance = (excludeAuthorizationHeader?: boolean) => {
    let params = {
      maxContentLength: 10000,
      timeout: 30000,
    }

    const headers: any = {}

    if (!excludeAuthorizationHeader && AppStateStore.authData) {
      headers['Authorization'] = AppStateStore.authData.authToken
      headers['X-Access-Token'] = AppStateStore.authData.accessToken
      headers['X-Refresh-Token'] = AppStateStore.authData.refreshToken
    }

    const instance = axios.create({
      ...params,
      baseURL: Config.API_URL,
      headers: headers,
    })

    instance.interceptors.response.use(response => {
      if (response.data.links) {
        try {
          // StoreDirectory.populateStores(response.data.links.map((l: any) => new Link().init(l)))
        } catch (e) {
          console.log(e)
        }
      }
      return response
    })


    return instance
  }
}

export default new ApiClient()
