import { observer } from 'mobx-react'
import React from 'react'
import { IconProps } from '@material-ui/core/Icon'
import * as icons from '@material-ui/icons'
import _ from 'lodash'

type Props = {
  icon: string
}

const DynamicIcon = observer(class DynamicIcon extends React.Component<Props & IconProps> {
  render () {
    const { icon, ...rest } = this.props

    const MappedIcon = _.get(icons, icon)

    if (!MappedIcon) {
      console.log(`Unknown DynamicIcon: "${this.props.icon}"`)
      return null
    }

    return <MappedIcon {...rest}/>
  }
});

export default DynamicIcon
