import BaseModel from "./BaseModel"
import QuickAction from './QuickAction'
import Link from './Link'

export default class UnauthorizedError extends BaseModel {
  header: string
  friendlyMessage: string
  quickAction: QuickAction
  links: Link[]

  setQuickAction (a: any) {
    return new QuickAction().init(a)
  }

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }
}
