import BaseModel from './BaseModel'
import { Moment } from 'moment'
import Link from './Link'

export class PaymentActivity extends BaseModel {
  header: string
  defaultText: string
  current: PaymentActivityItem[]
  links: Link[]

  setCurrent (a?: []) {
    return a ? a.map(aa => new PaymentActivityItem().init(aa)) : []
  }

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }
}

export class PaymentActivityItem extends BaseModel {
  date: Moment
  amount: string
  method: string

  getCasts (): any {
    return {
      date: 'datetime',
    }
  }
}
