import { Button, Collapse, Typography } from '@material-ui/core'
import { ActionCard as ActionCardModel } from '../models/ActionCard'
import React from 'react'
import { observer } from 'mobx-react'
import { observable, makeObservable } from 'mobx';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import classNames from 'classnames'

type Props = {
  actionCard: ActionCardModel
  onQuickActionClicked: (actionCard: ActionCardModel) => void
  collapseMobile?: boolean
}

export const ActionCard = observer(class ActionCard extends React.Component<Props> {
  private isMobile = false;
  private isExpanded = false;

  constructor(props: Props) {
    super(props);

    makeObservable<ActionCard, "isMobile" | "isExpanded">(this, {
      isMobile: observable,
      isExpanded: observable
    });
  }

  private shouldExpand (idx: number) {
    return idx < this.props.actionCard.displayRows || this.isExpanded
  }

  componentDidMount (): void {
    window.addEventListener('resize', this.onResize)

    this.checkIsMobileWidth()
  }

  componentWillUnmount (): void {
    window.removeEventListener('resize', this.onResize)
  }

  private onResize = (ev: UIEvent) => {
    this.checkIsMobileWidth()
  }

  private checkIsMobileWidth = () => {
    this.isMobile = window.innerWidth < 1024
  }

  private renderDesktopActionCard () {
    const actionCard = this.props.actionCard

    return <div className="desktop-action-card no-min-height bordered">
      <div className="desktop-action-card-content">
        {
          actionCard.header
            ? <div className={classNames('action-card-header', !this.isExpanded && this.props.actionCard.displayRows === 0 ? 'no-margin' : undefined)}>
              <b className="action-card-header-text">{actionCard.header}</b>
              {
                actionCard.displayRows < actionCard.rows.length
                  ? <Button onClick={() => this.isExpanded = !this.isExpanded}>{this.isExpanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}</Button>
                  : null
              }
            </div>
            : null
        }
        {
          actionCard.rows.map((row, idx) => <div key={idx} className="desktop-action-card-row">
            {
              row.type === 'Detail'
                ? <Collapse in={this.shouldExpand(idx)}>
                  <div className="action-card-row-container">
                    <div className="action-card-row-value">
                      {
                        row.value.description
                          ? <div className="action-card-row-value-description"><Typography color="primary">{row.value.description}</Typography></div>
                          : null
                      }
                      {
                        row.value.subLabel
                          ? <div className="action-card-row-value-sublabel">{row.value.subLabel}</div>
                          : null
                      }
                    </div>
                    {
                      row.value.detail.label || row.value.detail.value || row.value.detail.imageSource
                        ? <div className="action-card-row-value-detail">
                          {
                            row.value.detail.imageSource
                              ? <img src={row.value.detail.imageSource} className="action-card-row-value-image"/>
                              : null
                          }
                          {
                            row.value.detail.label
                              ? <div className="action-card-row-value-detail-label">{row.value.detail.label}</div>
                              : null
                          }
                          {
                            row.value.detail.value
                              ? <div className="action-card-row-value-detail-value">{row.value.detail.value}</div>
                              : null
                          }
                        </div>
                        : null
                    }
                  </div>
                </Collapse>
                : null
            }
            {
              row.type === 'Transaction'
                ? <Collapse in={this.shouldExpand(idx)}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}>
                      {row.value.date}
                    </div>
                    <div>
                      {row.value.amount}
                    </div>
                  </div>
                </Collapse>
                : null
            }
          </div>)
        }
        {
          actionCard.quickAction
            ? <>
              <div className="action-separator"/>
              <div className="action">
                <Button
                  color="primary"
                  variant={'text'}
                  fullWidth
                  onClick={() => this.props.onQuickActionClicked(actionCard)}
                >
                  {
                    // actionCard.quickAction.icon
                    //   ? <DynamicIcon icon={actionCard.quickAction.icon}/>
                    //   : null
                  }
                  {actionCard.quickAction.label}
                </Button>
              </div>
            </>
            : null
        }
      </div>
    </div>
  }

  private renderMobileActionCard () {
    const action = this.props.actionCard

    return <div className="action-container" style={{ marginBottom: 20 }}>
      {
        action.detail
          ? <div className="action-detail">
            <h3 className="header-h3">{action.detail.label}</h3>
            <h4 className="header-h4">{action.detail.value}</h4>
          </div>
          : null
      }
      {
        action.quickAction
          ? <div className="action-button">
            <Button
              fullWidth
              onClick={() => this.props.onQuickActionClicked(action)}
              color="primary"
              variant={action.quickAction.variant}
            >
              {action.quickAction.label}
            </Button>
          </div>
          : null
      }
    </div>
  }

  render () {
    return (this.props.collapseMobile && this.isMobile) ? this.renderMobileActionCard() : this.renderDesktopActionCard()
  }
});
