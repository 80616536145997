import BaseModel from './BaseModel'
import QuickAction from './QuickAction'

export class ActionCard extends BaseModel {
  header?: string
  category?: string
  displayRows: number
  rows: ActionCardRow[]
  quickAction?: QuickAction

  setQuickAction (a?: any) {
    return a ? new QuickAction().init(a) : undefined
  }
}

export type ActionCardRow = {
  type: 'Detail'
  value: ActionCardRowDetailValue
} | {
  type: 'Transaction'
  value: ActionCardRowTransactionValue
}

export type ActionCardRowDetailValue = {
  description?: string
  subLabel?: string
  detail: {
    imageSource?: string
    label?: string
    value?: string
  }
}

export type ActionCardRowTransactionValue = {
  date: string
  amount: string
  method: string
  error: string
}

