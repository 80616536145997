import { AxiosInstance } from 'axios'

import { configure } from "mobx"

configure({
  useProxies: "never",
  enforceActions: "never",
})

class ApiClientFactory {
  private apiClient: ApiClientInterface

  setInstance = (apiClient: ApiClientInterface) => {
    this.apiClient = apiClient
  }

  getInstance = (excludeAuthorizationHeader?: boolean) => {
    return this.apiClient.getInstance(excludeAuthorizationHeader)
  }
}

export interface ApiClientInterface {
  getInstance (excludeAuthorizationHeader?: boolean): AxiosInstance
}

export default new ApiClientFactory()
