export enum RowType {
  KeyValue = 'keyvalue',
  AccountCard = 'accountcard',
  IconCard = 'iconCard',
}

export enum RowAlertType {
  Warning = 'warning',
  Danger = 'danger',
}

export type SummaryRowKeyValueData = {
  key: string
  value: string
}

export type SummaryRowIconCardValue = {
  icon: string
  label: string
  subLabel: string
}

export type StepperSummarySectionRowDescriptor = {
  type: RowType
  alert: RowAlertType
  value: SummaryRowKeyValueData | any
}
