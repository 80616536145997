import React from 'react'
import { observer } from 'mobx-react'
import Link from '../../models/Link'
import { computed, observable, makeObservable } from 'mobx';
import ApiClient from '../../api/ApiClient'
import { Button, ButtonGroup, Card, CardContent, CircularProgress, FormGroup, Grid, Input, InputAdornment, TextField } from '@material-ui/core'
import { NavigationListener } from './DStepper'
import { AcceptJsAuthData, BankAccountDataType, CreditCardDataType, loadAcceptJs } from '../../common/AuthNet'
import DialogManager from '../dialog-provider/DialogManager'
import ApiClientFactory from '../../api/ApiClientFactory'
import * as cardValidator from 'card-validator'
import _ from 'lodash'
import AccountBox from '@material-ui/icons/AccountBox'
import AccountBalance from '@material-ui/icons/AccountBalance'
import CreditCard from '@material-ui/icons/CreditCard'
import CalendarToday from '@material-ui/icons/CalendarToday'
import Lock from '@material-ui/icons/Lock'
import LocationOn from '@material-ui/icons/LocationOn'
import DynamicIcon from '../DynamicIcon'
import moment from 'moment'
import { Autocomplete } from '@material-ui/lab'
import { v4 as uuidv4 } from 'uuid'
import { CyberSourceClient, CyberSourceKey } from '../../common/CyberSource'
import { HomeOutlined } from '@material-ui/icons'

type Props = {
  url: string
  navigationListener: NavigationListener
  onChange: (id: number) => void
}

export type TokenizedData = {
  dataDescriptor: string
  dataValue: string
}

type AuthNetTokenizer = {
  type: 'Accept.js'
  authentication: {
    clientKey: string
    name: string
  }
}

type CyberSourceTokenizer = {
  type: 'CyberSource'
  authentication: {
    keyId: string
    der: {
      format: string
      algorithm: string
      publicKey: string
    }
    jwk: {
      kty: string
      use: string
      kid: string
      n: string
      e: string
    }
  }
}

type OtherTokenizer = {
  type: 'Other'
}

type PaymentMethodTokenizer = AuthNetTokenizer | CyberSourceTokenizer | OtherTokenizer

type NewPaymentMethodType = {
  uuid?: string
  name: string
  formType: string
  method: string
  tokenizer: PaymentMethodTokenizer
}

type SavedPaymentMethodType = {
  id: number
  displayDetails: {
    icon: string
    label: string
    subLabel: string
    removeLabel: string
    removeConfirmation: string
    removeRel: string
  }
}

type PaymentMethodData = {
  paymentMethodHeader: string
  paymentFormLocale: string
  savedPaymentMethods: SavedPaymentMethodType[]
  preferredPaymentMethod: number | null
  newPaymentMethodHeader: string
  newPaymentMethods: NewPaymentMethodType[]
  postback: {
    label: string
    variant: 'contained' | 'outlined'
    icon: string
    postbackUrl: string
    postbackData: {
      save: boolean,
      type: string
      description: string
      last4: string
      method: string
      token: any
    }
    route: string
  }
  links: Link[]
}

const DPaymentMethod = observer(class DPaymentMethod extends React.Component<Props> {
  private paymentMethodData?: PaymentMethodData;
  private error?: string;
  private loading = false;
  private preferredPaymentMethodRemoved = false;

  private cardData = {
    nameOnCard: '',
    cardNumber: '',
    expMonth: '',
    expYear: '',
    securityCode: '',
    zipCode: '',
  };

  private bankData = {
    nameOnAccount: '',
    routingNumber: '',
    accountNumber: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
  };

  selectedNewPaymentMethodUuid?: string;

  constructor(props: Props) {
    super(props);

    makeObservable<DPaymentMethod, "paymentMethodData" | "error" | "loading" | "preferredPaymentMethodRemoved" | "cardData" | "bankData">(this, {
      paymentMethodData: observable,
      error: observable,
      loading: observable,
      preferredPaymentMethodRemoved: observable,
      cardData: observable,
      bankData: observable,
      selectedNewPaymentMethodUuid: observable,
      selectedNewPaymentMethod: computed,
      paymentMethodFormType: computed,
      preferredPaymentMethod: computed
    });
  }

  get selectedNewPaymentMethod(): NewPaymentMethodType | undefined {
    return this.selectedNewPaymentMethodUuid
      ? this.paymentMethodData?.newPaymentMethods.find(pm => pm.uuid === this.selectedNewPaymentMethodUuid)
      : undefined
  }

  get paymentMethodFormType() {
    if (this.selectedNewPaymentMethod) {
      if (this.selectedNewPaymentMethod.method === 'credit' || this.selectedNewPaymentMethod.method === 'debit' || this.selectedNewPaymentMethod.method === 'hsafsa') {
        return 'card'
      } else if (this.selectedNewPaymentMethod.method === 'ach') {
        return 'bank'
      }
    }

    return undefined
  }

  get preferredPaymentMethod(): SavedPaymentMethodType | undefined {
    const paymentMethodData = this.paymentMethodData
    if (!this.preferredPaymentMethodRemoved && paymentMethodData && paymentMethodData.preferredPaymentMethod) {
      return _.find(paymentMethodData.savedPaymentMethods, pm => String(pm.id) === String(paymentMethodData.preferredPaymentMethod))
    }

    return undefined
  }

  componentDidMount (): void {
    if (!this.paymentMethodData) {
      this.loadPaymentMethodData()
    }

    this.props.navigationListener.addListener(this.onStepNavigation)
  }

  componentWillUnmount (): void {
    this.props.navigationListener.removeListener(this.onStepNavigation)
  }

  private onStepNavigation = (action: string, next: Function) => {
    if (action === 'Next') {
      if (this.preferredPaymentMethod) {
        this.props.onChange(this.preferredPaymentMethod.id)
        next()
      } else {
        // tokenize payment method
        loadAcceptJs()
          .then(acceptJs => {
            let paymentData: {type: 'card', cardData: CreditCardDataType} | {type: 'bank', bankData: BankAccountDataType} | undefined = undefined

            let last4 = ''
            let description = ''
            let cardType: string|undefined

            if (this.paymentMethodFormType === 'card') {
              if (this.cardData.zipCode.trim().length < 1) {
                DialogManager.show({
                  title: 'Error',
                  content: <div>
                    Billing zip code is required
                  </div>,
                })
                return
              }

              paymentData = {
                type: 'card',
                cardData: {
                  cardNumber: this.cardData.cardNumber,
                  month: this.cardData.expMonth,
                  year: this.cardData.expYear,
                  zip: this.cardData.zipCode,
                  cardCode: this.cardData.securityCode,
                  fullName: this.cardData.nameOnCard,
                },
              }

              const numberValidation = cardValidator.number(this.cardData.cardNumber)

              if (numberValidation.card) {
                switch (numberValidation.card.type) {
                  case 'visa':
                    description = 'Visa'
                    cardType = '001'
                    break;
                  case 'mastercard':
                    description = 'MasterCard'
                    cardType = '002'
                    break;
                  case 'american-express':
                    description = 'American Express'
                    cardType = '003'
                    break;
                  case 'discover':
                    description = 'Discover'
                    cardType = '004'
                    break;
                  default:
                    description = 'Credit/Debit Card'
                    break;
                }
              } else {
                description = 'Credit/Debit Card'
              }

              last4 = this.cardData.cardNumber.substr(this.cardData.cardNumber.length - 4)
            } else if (this.paymentMethodFormType === 'bank') {
              paymentData = {
                type: 'bank',
                bankData: {
                  accountType: 'checking',
                  nameOnAccount: this.bankData.nameOnAccount,
                  routingNumber: this.bankData.routingNumber,
                  accountNumber: this.bankData.accountNumber,
                },
              }

              description = 'Checking'
              last4 = this.bankData.accountNumber.substr(this.bankData.accountNumber.length - 4)
            }

            if (paymentData) {
              if (this.selectedNewPaymentMethod?.tokenizer.type === 'Accept.js') {
                const authData: AcceptJsAuthData = {
                  apiLoginID: this.selectedNewPaymentMethod.tokenizer.authentication.name,
                  clientKey: this.selectedNewPaymentMethod.tokenizer.authentication.clientKey,
                }

                let extraData = {}

                if (this.paymentMethodFormType === 'bank') {
                  extraData = {
                    ...extraData,
                    address: this.bankData.address,
                    city: this.bankData.city,
                    state: this.bankData.state,
                    zipCode: this.bankData.zipCode,
                    nameOnCard: this.cardData.nameOnCard,
                  }
                }

                return acceptJs.tokenizePaymentMethod(authData, paymentData)
                  .then((response: AcceptJsResponseDataType) => {
                    ApiClientFactory.getInstance()
                      .post(this.paymentMethodData!.postback.postbackUrl, {
                        type: this.paymentMethodFormType,
                        last4: last4,
                        description: description,
                        token: response.opaqueData,
                        ...extraData,
                      })
                      .then(response => {
                        this.props.onChange(response.data.preferredPaymentMethod)
                        next()
                      })
                      .catch(() => DialogManager.show({
                        title: 'Error',
                        content: 'There was an error handling your payment method',
                      }))
                  })
                  .catch((response: AcceptJsResponseDataType) => {
                    const errors = response.messages.message.map(message => message.text)

                    DialogManager.show({
                      title: 'Error',
                      content: <div>
                        There was an error with the payment processor
                        {errors.map((e, idx) => <div key={idx}>{e}</div>)}
                      </div>,
                    })
                  })
              } else if (this.selectedNewPaymentMethod?.tokenizer.type === 'CyberSource') {
                const authData: CyberSourceKey = {
                  kid: this.selectedNewPaymentMethod.tokenizer.authentication.jwk.kid,
                  keystore: this.selectedNewPaymentMethod.tokenizer.authentication.jwk,
                }

                const csTokenizer = paymentData.type === 'card'
                  ? (new CyberSourceClient()).tokenizePaymentMethod(authData, {
                    cardType: cardType ?? '',
                    cardNumber: paymentData.cardData.cardNumber,
                    securityCode: paymentData.cardData.cardCode || '',
                    expiryMonth: paymentData.cardData.month,
                    expiryYear: paymentData.cardData.year,
                  }).then(response => ({
                    type: 'card',
                    data: response,
                  }))
                  : new Promise((resolve, reject) => {
                    const bankData = (paymentData as {bankData: BankAccountDataType}).bankData
                    const data = {
                      accountType: bankData.accountType,
                      accountNumber: bankData.accountNumber,
                      nameOnAccount: bankData.nameOnAccount,
                      routingNumber: bankData.routingNumber,
                    }
                    resolve({
                      type: 'bank',
                      data,
                    })
                  })

                return csTokenizer.then((response: any) => {
                  let extraData = {}

                  if (paymentData?.type === 'card') {
                    extraData = {
                      ...extraData,
                      expMonth: paymentData.cardData.month,
                      expYear: paymentData.cardData.year,
                      zipCode: this.cardData.zipCode,
                      nameOnCard: this.cardData.nameOnCard,
                    }
                  } else if (paymentData?.type === 'bank') {
                    extraData = {
                      ...extraData,
                      address: this.bankData.address,
                      city: this.bankData.city,
                      state: this.bankData.state,
                      zipCode: this.bankData.zipCode,
                    }
                  }

                  ApiClientFactory.getInstance()
                    .post(this.paymentMethodData!.postback.postbackUrl, {
                      type: this.paymentMethodFormType,
                      last4: last4,
                      description: description,
                      token: response,
                      ...extraData,
                    })
                    .then(response => {
                      this.props.onChange(response.data.preferredPaymentMethod)
                      next()
                    })
                    .catch(() => DialogManager.show({
                      title: 'Error',
                      content: 'There was an error handling your payment method',
                    }))
                })
                  .catch((err: any) => {
                    console.log('error', err)
                    DialogManager.show({
                      title: 'Error',
                      content: <div>
                        There was an error with the payment processor
                      </div>,
                    })

                  })
              } else {
                // invalid tokenizer
              }
            } else {
              // invalid payment type
            }
          })
          .catch(() => DialogManager.show({
            title: 'Error',
            content: 'There was an error loading the payment processor interface',
          }))
      }

      return true
    } else {
      return false
    }
  }

  private loadPaymentMethodData = () => {
    this.loading = true
    this.preferredPaymentMethodRemoved = false

    ApiClient.getInstance().get(this.props.url)
      .then(response => {
        this.paymentMethodData = response.data
        this.paymentMethodData?.newPaymentMethods.forEach(pm => pm.uuid = uuidv4().toString())
        this.selectedNewPaymentMethodUuid = this.paymentMethodData!.newPaymentMethods.length ? this.paymentMethodData!.newPaymentMethods[0].uuid : undefined
      })
      .catch(error => this.error = 'There was an error loading the payment method data')
      .then(() => this.loading = false)
  }

  private renderPaymentForm = () => {
    const paymentMethodData = this.paymentMethodData
    if (this.preferredPaymentMethod) {
      return this.renderSavedPaymentMethod(this.preferredPaymentMethod)
    } else if (paymentMethodData && paymentMethodData.newPaymentMethods.length) {
      return <div>
        <ButtonGroup color="primary" aria-label="button group">
          {
            paymentMethodData.newPaymentMethods.map(pm => <Button
              key={pm.method}
              size="small"
              variant="contained"
              color={pm.uuid === this.selectedNewPaymentMethod?.uuid ? 'primary' : 'default'}
              onClick={() => this.selectedNewPaymentMethodUuid = pm.uuid}
            >{pm.name}</Button>)
          }
        </ButtonGroup>
        <div>
          {
            this.selectedNewPaymentMethod
              ? this.renderPaymentMethodForm(this.selectedNewPaymentMethod.method)
              : null
          }
        </div>
      </div>
    } else {
      return 'No payment methods'
    }
  }

  private renderSavedPaymentMethod = (paymentMethod: SavedPaymentMethodType) => {
    return <Card>
      <CardContent style={{ paddingBottom: 16 }}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <div><b>{paymentMethod.displayDetails.label}</b></div>
            {
              paymentMethod.displayDetails.subLabel
                ? <div>{paymentMethod.displayDetails.subLabel}</div>
                : null
            }
          </div>
          <div style={{ paddingLeft: 6 }}>
            <DynamicIcon icon={paymentMethod.displayDetails.icon} style={{ width: 60 }}/>
          </div>
        </div>
        <Button
          color="primary"
          onClick={() => this.preferredPaymentMethodRemoved = true}
        >{paymentMethod.displayDetails.removeLabel}</Button>
      </CardContent>
    </Card>
  }

  private renderPaymentMethodForm = (type: string) => {
    if (this.paymentMethodFormType === 'card') {
      return <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <FormGroup row>
            <Input
              id="nameoncard"
              name="nameoncard"
              fullWidth
              value={this.cardData.nameOnCard}
              onChange={ev => this.cardData.nameOnCard = ev.target.value}
              placeholder="Name on Card"
              startAdornment={<InputAdornment position="start"><CreditCard style={{ color: '#999' }}/></InputAdornment>}
              inputProps={{
                autoComplete: 'cc-name',
              }}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup row>
            <Input
              id="cardnumber"
              name="cardnumber"
              fullWidth
              value={this.cardData.cardNumber}
              onChange={ev => this.cardData.cardNumber = ev.target.value}
              placeholder="Card Number"
              startAdornment={<InputAdornment position="start"><AccountBox style={{ color: '#999' }}/></InputAdornment>}
              inputProps={{
                autoComplete: 'cc-number',
              }}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup row>
            <Autocomplete
              options={['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']}
              id="cc-exp-month"
              style={{ width: '100%' }}
              inputValue={this.cardData.expMonth}
              onInputChange={(event, newInputValue) => this.cardData.expMonth = newInputValue}
              renderInput={(params) => {
                const props = {
                  ...params,
                  InputProps: {
                    ...(params.InputProps),
                    startAdornment: <InputAdornment position="start">
                      <CalendarToday style={{ color: '#999' }}/>
                    </InputAdornment>,
                    inputProps: {
                      ...(params.inputProps),
                      autoComplete: 'cc-exp-month',
                    },
                  },
                }

                return <TextField
                  fullWidth
                  type="number"
                  {...props}
                  placeholder="Exp. MM"
                  margin="none"/>
              }}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup row>
            <Autocomplete
              options={_.range(moment().year(), moment().year() + 11).map(y => String(y))}
              id="cc-exp-year"
              style={{ width: '100%' }}
              inputValue={this.cardData.expYear}
              onInputChange={(event, newInputValue) => this.cardData.expYear = newInputValue}
              renderInput={(params) => {
                const props = {
                  ...params,
                  InputProps: {
                    ...(params.InputProps),
                    startAdornment: <InputAdornment position="start">
                      <CalendarToday style={{ color: '#999' }}/>
                    </InputAdornment>,
                    inputProps: {
                      ...(params.inputProps),
                      autoComplete: 'cc-exp-year',
                    },
                  },
                }

                return <TextField
                  fullWidth
                  type="number"
                  {...props}
                  placeholder="YYYY"
                  margin="none"/>
              }}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup row>
            <Input
              id="cvv2"
              name="cvv2"
              inputProps={{
                autoComplete: 'cc-csc',
              }}
              startAdornment={<InputAdornment position="start"><Lock style={{ color: '#999' }}/></InputAdornment>}
              placeholder="CVV"
              fullWidth
              value={this.cardData.securityCode}
              onChange={ev => this.cardData.securityCode = ev.target.value}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup row>
            <Input
              id="postal-code"
              name="postal-code"
              inputProps={{
                autoComplete: 'shipping postal-code',
              }}
              startAdornment={<InputAdornment position="start"><LocationOn style={{ color: '#999' }}/></InputAdornment>}
              placeholder="Zip Code"
              fullWidth
              value={this.cardData.zipCode}
              onChange={ev => this.cardData.zipCode = ev.target.value}
            />
          </FormGroup>
        </Grid>
      </Grid>
    } else if (this.paymentMethodFormType === 'bank') {
      return <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <FormGroup row>
            <Input
              placeholder="Name on Bank Account"
              startAdornment={
                <InputAdornment position="start">
                  <AccountBox style={{ color: '#999' }}/>
                </InputAdornment>
              }
              fullWidth
              value={this.bankData.nameOnAccount}
              onChange={ev => this.bankData.nameOnAccount = ev.target.value}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup row>
            <Input
              placeholder="Routing Number"
              startAdornment={
                <InputAdornment position="start">
                  <AccountBalance style={{ color: '#999' }}/>
                </InputAdornment>
              }
              fullWidth
              value={this.bankData.routingNumber}
              onChange={ev => this.bankData.routingNumber = ev.target.value}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup row>
            <Input
              placeholder="Account Number"
              startAdornment={
                <InputAdornment position="start">
                  <AccountBox style={{ color: '#999' }}/>
                </InputAdornment>
              }
              fullWidth
              value={this.bankData.accountNumber}
              onChange={ev => this.bankData.accountNumber = ev.target.value}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup row>
            <Input
              placeholder="Address"
              startAdornment={
                <InputAdornment position="start">
                  <HomeOutlined style={{ color: '#999' }}/>
                </InputAdornment>
              }
              fullWidth
              value={this.bankData.address}
              onChange={ev => this.bankData.address = ev.target.value}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup row>
            <Input
              placeholder="City"
              fullWidth
              value={this.bankData.city}
              onChange={ev => this.bankData.city = ev.target.value}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={2}>
          <FormGroup row>
            <Input
              placeholder="State"
              fullWidth
              value={this.bankData.state}
              onChange={ev => this.bankData.state = ev.target.value}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={4}>
          <FormGroup row>
            <Input
              placeholder="Zip"
              fullWidth
              value={this.bankData.zipCode}
              onChange={ev => this.bankData.zipCode = ev.target.value}
            />
          </FormGroup>
        </Grid>
      </Grid>
    } else {
      return null
    }
  }

  render () {
    return this.loading
      ? <CircularProgress/>
      : this.error
        ? <div>{this.error}</div>
        : this.renderPaymentForm()
  }
});

export default DPaymentMethod
