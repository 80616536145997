import React, { SyntheticEvent } from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { AppBar, BottomNavigation, BottomNavigationAction, CircularProgress, createMuiTheme, IconButton, Link, Menu, MenuItem, Theme, Toolbar } from "@material-ui/core"
import { observer } from "mobx-react"
import { ThemeProvider } from '@material-ui/styles'
import ApiClientFactory from './api/ApiClientFactory'
import ApiClient from './api/ApiClient'
import MenuIcon from '@material-ui/icons/Menu'
import { autorun, computed, observable, toJS, makeObservable } from 'mobx';
import Interaction from './models/Interaction'
import { AppRoutes, mapRouteNameToRoute, Routes } from './routes/AppRoutes'
import NotFoundView from './views/NotFoundView'
import { InteractionContext } from './InteractionContext'
import AdapterLink from './components/AdapterLink'
import { route } from './routes/routes'
import QuickAction from './models/QuickAction'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import EventBus, { EventBusContext } from './common/EventBus'
import LoginDialog from './components/LoginDialog'
import AppStateStore from './stores/AppStateStore'
import DialogProvider from './components/dialog-provider/DialogProvider'
import classNames from 'classnames'
import DynamicIcon from './components/DynamicIcon'
import { TreeItem, TreeView } from '@material-ui/lab'
import Config from './common/Config'
import { joinUrls } from './common/Util'
import { RootRouteContext } from './RootRouteContext'
import GenericLoginView from './views/GenericLoginView'
import { CommonContext } from './CommonContext';
import _ from 'lodash'

ApiClientFactory.setInstance(ApiClient)

const App = observer(class App extends React.Component {
  render () {
    return <Router>
      <Switch>
        <Route
          component={GenericLoginView}
          path={['/', Routes.terms, Routes.policy, Routes.help]}
          exact={true}
        />
        <Route
          component={AppComponent}
          path="/:slug"
          exact={false}
          sensitive={true}
          strict={true}
        />
        <Route component={NotFoundView}/>
      </Switch>
    </Router>
  }
});

const AppComponent = observer(
  class AppComponent extends React.Component<RouteComponentProps<{slug: string}>> {
    private interaction?: Interaction;
    private eventBus = new EventBus()

    constructor(props: RouteComponentProps<{slug: string}>) {
      super(props);

      makeObservable<AppComponent, "interaction">(this, {
        interaction: observable,
        theme: computed
      });
    }

    get theme() {
      const palette: any = {
        primary: { main: '#3c2d82' },
      }

      if (this.interaction && this.interaction.client) {
        this.interaction.client.colors.forEach(color => {
          palette[color.type] = {
            main: color.code.hex
          }
        })
      }

      return createMuiTheme({
        palette: palette,
      })
    }

    private getInteraction = async () => {
      const params: any = {
        data: this.props.match.params.slug,
      }

      if (Config.API_SUB_ID) {
        params.sub = Config.API_SUB_ID
      }

      return ApiClientFactory.getInstance()
        .get(`https://e65d2wtk52.execute-api.us-east-1.amazonaws.com/dev/interaction`, { params })
    }

    private renderLoader = () => {
      return <div className="app-loader-container">
        <CircularProgress/>
      </div>
    }

    private renderFooter = (interaction: Interaction) => {
      return <div className="app-footer">
        <Link color="inherit" component={AdapterLink} to={route(joinUrls([this.props.match.url, Routes.policy]))}>{interaction.policy.label}</Link>
        &nbsp;
        &nbsp;
        &nbsp;
        <Link color="inherit" component={AdapterLink} to={route(joinUrls([this.props.match.url, Routes.terms]))}>{interaction.term.label}</Link>
      </div>
    }

    private setFavIcon (src: string | undefined) {
      let link: any = document.querySelector("link[rel~='icon']")

      if (!src && link) {
        link.remove()
      }

      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.getElementsByTagName('head')[0].appendChild(link)
      }

      link.href = src
    }

    componentDidMount (): void {
      AppStateStore.restoreAuthData()

      let prevAuthData: any = undefined

      autorun(() => {
        // need to update when auth token changes
        const authData = toJS(AppStateStore.authData)

        if (!_.isEqual(prevAuthData, authData)) {
          // auth data has changed, need to reload the interaction

          prevAuthData = authData
          this.getInteraction()
            .then(response => {
              this.interaction = new Interaction().init(response.data)
              this.interaction.fetchQuickActions()
              this.interaction.fetchClient()
            })
            .catch(error => {
              // TODO: show error
              console.log(error)
            })
        }
      })

      autorun(() => {
        document.title = this.interaction?.client?.shortName || 'Patient App'
        this.setFavIcon(this.interaction?.client?.faviconSource)
      })

      this.getInteraction()
        .then(response => {
          this.interaction = new Interaction().init(response.data)
          this.interaction.fetchQuickActions()
          this.interaction.fetchClient()
        })
        .catch(error => {
          // TODO: show error
          console.log(error)
        })
    }

    render () {
      return (this.interaction && this.interaction.client)
        ? this.renderApp(this.interaction)
        : this.renderLoader()
    }

    private renderApp = (interaction: Interaction) => {
      const AppRouteSwitchRouter = withRouter(AppRouteSwitch)

      return <ThemeProvider theme={this.theme}>
        <RootRouteContext.Provider value={{ rootRoute: this.props.match.url }}>
          <DialogProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <div className={classNames(['app-container', AppStateStore.authData ? 'is-authenticated' : undefined])}>
                <EventBusContext.Provider value={{ eventBus: this.eventBus }}>
                  <InteractionContext.Provider value={{ interaction: interaction }}>
                    <CommonContext.Provider value={{ commonData: interaction }}>
                      <div className="desktop-header hide-mobile">
                        {
                          interaction?.client?.logoSource
                            ? <div className="desktop-client-logo">
                              <img src={interaction.client.logoSource} style={{ maxWidth: '100%', maxHeight: '100%' }}/>
                            </div>
                            : null
                        }
                        <div className="desktop-auth-header">
                          <AuthHeaderRouter rootRoute={this.props.match.url}/>
                        </div>
                      </div>
                      <div className="hide-desktop">
                        {
                          AppStateStore.authData
                            ? <AuthHeaderRouter rootRoute={this.props.match.url}/>
                            : <AppMenuRouter
                              rootRoute={this.props.match.url}
                              position="top"
                              quickActions={interaction.quickActions}
                              theme={this.theme}
                            />
                        }
                      </div>
                      {
                        (interaction.client && interaction.client.logoSource)
                          ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} className="hide-desktop">
                            <div className="app-client-logo">
                              <img src={interaction.client.logoSource} style={{ maxWidth: '100%', maxHeight: '100%' }}/>
                            </div>
                          </div>
                          : null
                      }
                      <div className="app-content-wrapper">
                        <div className="desktop-left-nav hide-mobile">
                          <AppMenuRouter
                            rootRoute={this.props.match.url}
                            position="left"
                            quickActions={interaction.quickActions}
                            theme={this.theme}
                          />
                        </div>
                        <div
                          className={classNames(['app-content', AppStateStore.authData ? 'is-authenticated' : undefined])}>
                          <AppRouteSwitchRouter/>
                        </div>
                      </div>
                      {interaction ? <LoginDialog goBackOnCancel={true} eventBus={this.eventBus} client={interaction.client}/> : null}
                      {
                        AppStateStore.authData
                          ? <>
                            <div className="bottom-navigation">
                              <AppMenuRouter
                                rootRoute={this.props.match.url}
                                position="bottom"
                                quickActions={interaction.quickActions}
                                theme={this.theme}
                              />
                            </div>
                            <div className="hide-mobile">
                              {this.renderFooter(interaction)}
                            </div>
                          </>
                          : <div>
                            {this.renderFooter(interaction)}
                          </div>

                      }
                    </CommonContext.Provider>
                  </InteractionContext.Provider>
                </EventBusContext.Provider>
              </div>
            </MuiPickersUtilsProvider>
          </DialogProvider>
        </RootRouteContext.Provider>
      </ThemeProvider>
    }
  }
);

const AppRouteSwitch = observer(class AppRouteSwitch extends React.Component<RouteComponentProps> {
  render () {
    return <Switch>
      {AppRoutes.map(r => {
        const { component, path, ...rest } = r
        return (
          <Route
            key={path}
            path={`${this.props.match.path}${r.path}`}
            component={component}
            {...rest}
          />
        )
      })}
      <Route component={NotFoundView}/>
    </Switch>
  }
});

type AuthHeaderProps = {
  rootRoute: string
} & RouteComponentProps

const AuthHeader = observer(class AuthHeader extends React.Component<AuthHeaderProps> {
  render () {
    return <div className="auth-nav-bar">
      {
        AppStateStore.authData
          ? <div className="header-help-link">
            <Link
              color="inherit"
              style={{ marginLeft: 'auto' }}
              href=""
              onClick={(ev: SyntheticEvent) => {
                ev.preventDefault()
                AppStateStore.clearAuthData()
                this.props.history.push('/')
              }}
            >Log Out</Link>
          </div>
          : null
      }
      <div>
        <Link component={AdapterLink} color="inherit" style={{ marginLeft: 'auto' }} to={route(joinUrls([this.props.rootRoute, Routes.help]))}>Need Help?</Link>
      </div>
    </div>
  }
});

export const AuthHeaderRouter = withRouter(AuthHeader)

type AppMenuProps = {
  rootRoute: string
  position: 'top' | 'bottom' | 'left'
  quickActions: QuickAction[]
  theme: Theme
} & RouteComponentProps

const AppMenu = observer(class AppMenu extends React.Component<AppMenuProps> {
  private isMenuOpen = false;
  private showOverflowMenu = false;
  private menuIconRef = React.createRef<HTMLAnchorElement>()

  private overflowButtonRef = React.createRef<HTMLAnchorElement>()

  constructor(props: AppMenuProps) {
    super(props);

    makeObservable<AppMenu, "isMenuOpen" | "showOverflowMenu">(this, {
      isMenuOpen: observable,
      showOverflowMenu: observable,
      activeView: computed
    });
  }

  get activeView() {
    return AppStateStore.activeView || ''
  }

  private handleMenuClose = () => {
    this.isMenuOpen = false
  }

  private renderLeftMenu () {
    return <div className="desktop-left-nav">
      <TreeView
        selected={this.activeView}
        onNodeToggle={() => {
        }}
        onNodeSelect={() => {
        }}
      >
        {
          this.props.quickActions.map(quickAction => {
            return <TreeItem
              key={quickAction.rel}
              nodeId={quickAction.route}
              label={<div className="desktop-left-nav-item" style={this.activeView === quickAction.route ? { color: this.props.theme.palette.primary.main } : undefined}>
                <DynamicIcon icon={quickAction.icon} className="nav-icon"/> <span className="nav-label">{quickAction.label}</span>
              </div>}
              onClick={() => {
                this.props.history.push(route(joinUrls([this.props.rootRoute, mapRouteNameToRoute(quickAction.route)]), {}, { rel: quickAction.rel }))
              }}
            />
          })
        }
      </TreeView>
    </div>
  }

  private renderTopMenu () {
    return <AppBar className="mobile-top-nav hide-desktop" position="static">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" innerRef={this.menuIconRef} onClick={() => this.isMenuOpen = true}>
          <MenuIcon/>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={this.menuIconRef.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={this.isMenuOpen}
          onClose={this.handleMenuClose}
        >
          {
            this.props.quickActions.map(quickAction => <MenuItem
              key={quickAction.rel}
              onClick={() => {
                this.handleMenuClose()
                this.props.history.push(route(joinUrls([this.props.rootRoute, mapRouteNameToRoute(quickAction.route)]), {}, { rel: quickAction.rel }))
              }}
            >{quickAction.label}</MenuItem>)
          }
        </Menu>
        <Link component={AdapterLink} color="inherit" style={{ marginLeft: 'auto', fontSize: '10pt' }} to={route(joinUrls([this.props.rootRoute, Routes.help]))}>Need Help?</Link>
      </Toolbar>
    </AppBar>
  }

  private renderBottomMenu () {
    const quickActions = this.props.quickActions.slice()
    let overflowActions: QuickAction[] = []

    if (this.props.quickActions.length > 4) {
      overflowActions = quickActions.splice(3)
    }

    return <>
      <BottomNavigation
        value={AppStateStore.activeView}
        className="mobile-bottom-nav hide-desktop"
        onChange={(event, newValue) => {
          // setValue(newValue);
        }}
        showLabels
      >
        {
          quickActions.map(quickAction => {
            return <BottomNavigationAction
              key={quickAction.rel}
              value={quickAction.route}
              onClick={() => {
                this.handleMenuClose()
                this.props.history.push(route(joinUrls([this.props.match.url, mapRouteNameToRoute(quickAction.route)]), {}, { rel: quickAction.rel }))
              }}
              label={quickAction.label}
              icon={<DynamicIcon icon={quickAction.icon}/>}
            />
          })
        }
        {
          overflowActions.length
            ? <BottomNavigationAction
              buttonRef={this.overflowButtonRef}
              key="__overflow"
              label="More"
              icon={<DynamicIcon icon="MoreHoriz"/>}
              onClick={() => {
                this.showOverflowMenu = !this.showOverflowMenu
              }}
            />
            : null
        }
      </BottomNavigation>
      {
        overflowActions.length
          ? <Menu
            anchorEl={this.overflowButtonRef.current}
            open={this.showOverflowMenu}
            keepMounted
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            onClose={() => this.showOverflowMenu = false}
          >
            {
              overflowActions.map(quickAction => <MenuItem
                key={quickAction.rel}
                onClick={() => {
                  this.showOverflowMenu = false
                  this.props.history.push(route(joinUrls([this.props.rootRoute, mapRouteNameToRoute(quickAction.route)]), {}, { rel: quickAction.rel }))
                }}
              >{quickAction.label}</MenuItem>)
            }
          </Menu>
          : null
      }
    </>
  }

  render () {
    switch (this.props.position) {
      case 'bottom':
        return this.renderBottomMenu()
      case 'top':
        return this.renderTopMenu()
      case 'left':
        return this.renderLeftMenu()
    }

    return null
  }
});

export const AppMenuRouter = withRouter(AppMenu)
export default App
