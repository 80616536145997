import React from 'react'
import { observer } from 'mobx-react'
import { observable, makeObservable } from 'mobx';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import { DialogButton } from './DialogDescriptor'

type Props = {
  title: string
  content: React.ReactNode
  buttons?: DialogButton[]
  onExited: () => void
}

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SimpleDialog = observer(class SimpleDialog extends React.Component<Props> {
  private isOpen = true;

  private handleDialogClose = () => {
    this.isOpen = false
  }

  private handleButtonClick = (button: DialogButton) => {
    button.onClick(() => this.isOpen = false)
  }

  constructor(props: Props) {
    super(props);

    makeObservable<SimpleDialog, "isOpen">(this, {
      isOpen: observable
    });
  }

  render () {
    return <Dialog open={this.isOpen} onExited={this.props.onExited} onClose={this.handleDialogClose} TransitionComponent={Transition}>
      <DialogTitle>{this.props.title}</DialogTitle>
      <DialogContent>
        {this.props.content}
      </DialogContent>
      <DialogActions>
        {
          this.props.buttons
            ? this.props.buttons.map((button, idx) => <Button key={idx} onClick={() => this.handleButtonClick(button)} color="primary">{button.label}</Button>)
            : <Button onClick={this.handleDialogClose} color="primary">OK</Button>
        }

      </DialogActions>
    </Dialog>
  }
});

export default SimpleDialog
