import Config from "./Config"
import flex from '@cybersource/flex-sdk-web'

export type CyberSourceKey = {
  kid: string
  keystore: any
}

export type CyberSourcePaymentMethod = {
  cardNumber: string
  securityCode: string
  cardType: string
  expiryMonth: string
  expiryYear: string
}

export type CyberSourceTokenizedPaymentMethod = {
  keyId: string
  token: any
  maskedPan: string
  cardType: string
  timestamp: number
  signedFields: string
  signature: string
}

export class CyberSourceClient {
  tokenizePaymentMethod = async (key: CyberSourceKey, data: CyberSourcePaymentMethod): Promise<CyberSourceTokenizedPaymentMethod> => {
    const options = {
      ...key,
      cardInfo: data,
      encryptionType: 'rsaoaep256',
      production: !Config.CYBERSOURCE_TEST_MODE,
    };

    return new Promise<CyberSourceTokenizedPaymentMethod>((resolve, reject) => {
      flex.createToken(options, (response: any) => {
        if (response.error) {
          reject(response.error)
        } else {
          resolve(response)
        }
      })
    })
  }
}
