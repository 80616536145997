import BaseModel from "./BaseModel"
import Link from "./Link"
import QuickAction, { QuickActions } from './QuickAction'
import ApiClientFactory from '../api/ApiClientFactory'
import { getRelUrl } from '../common/Util'
import _ from 'lodash'
import { computed, observable, makeObservable } from 'mobx';
import Client from './Client'
import queryString from 'query-string'

export default class Interaction extends BaseModel {
  id: number
  greeting: string
  help: {
    label: string
    rel: string
  }
  policy: {
    label: string
    rel: string
  }
  term: {
    label: string
    rel: string
  }
  links: Link[] = []

  private _quickActions?: QuickActions;
  client?: Client;

  fetchQuickActions = () => {
    let url = getRelUrl(this.links, 'quickactions')

    if (url) {
      const qs = queryString.parse(window.location.search)

      if (qs.rel) {
        if (url.indexOf('?') > -1) {
          url = `${url}&rel=${qs.rel}`
        } else {
          url = `${url}?rel=${qs.rel}`
        }
      }

      ApiClientFactory.getInstance()
        .get(url)
        .then(response => {
          this._quickActions = new QuickActions().init(response.data)
        })
    }
  }

  fetchClient = () => {
    const url = getRelUrl(this.links, 'client')
    if (url) {
      ApiClientFactory.getInstance()
        .get(url)
        .then(response => {
          this.client = response.data
        })
    }
  }

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable<Interaction, "_quickActions">(this, {
      _quickActions: observable,
      client: observable,
      quickActions: computed
    });
  }

  get quickActions() {
    return this._quickActions
      ? this._quickActions.current
      : []
  }

  getQuickActionByRel = (rel: string): QuickAction | undefined => {
    return this._quickActions
      ? _.find(this._quickActions.current, qa => qa.rel === rel)
      : undefined
  }

  getQuickActionLinkByRel = (rel: string): Link | undefined => {
    return this._quickActions
      ? _.find(this._quickActions.links, l => l.rel === rel)
      : undefined
  }

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }

  getCasts (): {} {
    return {
      id: 'number',
    }
  }
}
