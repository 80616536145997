import BaseModel from './BaseModel'
import { ActionCard } from './ActionCard'
import Link from './Link'

export class PaymentActions extends BaseModel {
  greeting: string
  text: string
  detail: {
    imageSource: string | undefined
    label: string | undefined
    value: string | undefined
  } | undefined
  current: ActionCard[]
  links: Link[]

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }
}
