import BaseModel from './BaseModel'
import Link from './Link'
import { ActionCard } from './ActionCard'

export class ManagePaymentPlan extends BaseModel {
  current: ActionCard[]
  links: Link[]

  setCurrent (a?: []) {
    return a ? a.map(aa => new ActionCard().init(aa)) : []
  }

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }
}
